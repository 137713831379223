import { Box, Radio, styled } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import CheckIcon from './checkmark.svg';

const StyledRadio = styled(Radio)(({ theme }) => ({
  padding: 0,
}));

const CustomIcon = styled('span')({
  width: 20,
  height: 20,
  border: '1px solid #D1D5DB',
  borderRadius: '50%',
  display: 'inline-block',
});

const FieldRadioCheckboxButton = ({ id, name, value, onChange, checked, className }) => {
  return (
    <StyledRadio
      id={id}
      name={name}
      value={value}
      onChange={onChange}
      checked={checked}
      className={className}
      icon={<CustomIcon />}
      checkedIcon={
        <Box
          width={20}
          height={20}
          bgcolor="primary.main"
          borderRadius="50%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{
            animation: '0.3s ease-in-out scale',
            '@keyframes scale': {
              '0%': { transform: 'scale(1)' },
              '50%': { transform: 'scale(1.2)' },
              '100%': { transform: 'scale(1)' },
            },
            '& img': {
              width: 12,
              height: 12,
            },
          }}
        >
          <img src={CheckIcon} alt="checked" />
        </Box>
      }
    />
  );
};

FieldRadioCheckboxButton.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  className: PropTypes.string,
};

FieldRadioCheckboxButton.defaultProps = {
  onChange: null,
  checked: false,
  className: null,
};

export default FieldRadioCheckboxButton;
