import { Stack, Typography } from '@mui/material';
import React from 'react';
import { ExternalLink, NamedLink } from '..';
import { stringify } from '../../util/urlHelpers';

export const FooterLinksSection = ({ title, items }) => (
  <Stack flex={{ xs: '40%', md: 1 }} gap={2}>
    <Typography variant="h3" fontWeight={600} color="textSecondary" fontSize={{ xs: 14, md: 16 }}>
      {title}
    </Typography>
    <Stack gap={1.5}>
      {items.map(({ name, route, search, href }) => {
        if (href) {
          return (
            <ExternalLink key={name} href={href}>
              <Typography variant="h4" color="textPrimary" fontSize={{ xs: 14, md: 16 }}>
                {name}
              </Typography>
            </ExternalLink>
          );
        }
        if (route) {
          return (
            <NamedLink to={{ search: stringify(search || {}) }} key={name} name={route}>
              <Typography variant="h4" color="textPrimary" fontSize={{ xs: 14, md: 16 }}>
                {name}
              </Typography>
            </NamedLink>
          );
        }
        return (
          <Typography key={name} variant="h4" color="textPrimary" fontSize={{ xs: 14, md: 16 }}>
            {name}
          </Typography>
        );
      })}
    </Stack>
  </Stack>
);
