import { Stack } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const SectionContainer = ({ bgColor, children, sx = {} }) => {
  return (
    <Stack bgcolor={bgColor} width="100%">
      <Stack
        direction="column"
        alignItems="center"
        justifyContent="center"
        mx="auto"
        py={8}
        px={3}
        sx={{
          width: '100%',
          maxWidth: 1096,
          overflow: 'hidden',
          '@media (max-width: 767px)': {
            px: 2,
          },
          '@media (max-width: 1439px)': {
            maxWidth: '100vw',
            borderRadius: 0,
          },
          ...sx,
        }}
      >
        {children}
      </Stack>
    </Stack>
  );
};

SectionContainer.propTypes = {
  bgColor: PropTypes.string.isRequired,
  children: PropTypes.node,
};

SectionContainer.defaultProps = {
  children: null,
};

export default SectionContainer;
