import { useApi } from "./useApi"

const FX_RATE_KEY = 'FX_RATE_KEY'

export const useLoadFxRate = (countryCode) => {
  const fromCurrency = countryCode === 'PL' ? 'EUR' : 'PLN';
  const toCurrency = countryCode === 'PL' ? 'PLN' : 'EUR';

  const savedFxRate = typeof window !== 'undefined' && window.sessionStorage.getItem(FX_RATE_KEY);
  const loadedFxRate = useApi(
    async api => {
      const result = await api.fetchFxRate(fromCurrency, toCurrency);
      if (typeof window !== 'undefined') {
        window.sessionStorage.setItem(FX_RATE_KEY, JSON.stringify(result.data.fxRate));
      }
      return result.data.fxRate;
    }, [countryCode]
  );

  const fxRate = loadedFxRate.data || savedFxRate ? JSON.parse(savedFxRate) : null;
  return { ...fxRate, loading: loadedFxRate.loading };
}
