/**
 * Export loadData calls from ducks modules of different containers
 */
import { loadData as ContactDetailsPageLoader } from './ContactDetailsPage/ContactDetailsPage.duck';
import { loadData as EditListingPageLoader } from './EditListingPage/EditListingPage.duck';
import { loadData as InboxPageLoader } from './InboxPage/InboxPage.duck';
import { loadData as ListingPageLoader } from './ListingPage/ListingPage.duck';
import { loadData as SearchPageLoader } from './SearchPage/SearchPage.duck';
import { loadData as VerifyYourEmailPageLoader } from './VerifyYourEmailPage/VerifyYourEmailPage.duck';
import { loadData as VerifyYourPhonePageLoader } from './VerifyYourPhonePage/VerifyYourPhonePage.duck';
import { loadData as VerifyYourBusinessPageLoader } from './VerifyYourBusinessPage/VerifyYourBusinessPage.duck';

const getPageDataLoadingAPI = () => ({
  ContactDetailsPage: {
    loadData: ContactDetailsPageLoader,
  },
  EditListingPage: {
    loadData: EditListingPageLoader,
  },
  VerifyYourEmailPage: {
    loadData: VerifyYourEmailPageLoader,
  },
  VerifyYourPhonePage: {
    loadData: VerifyYourPhonePageLoader,
  },
  VerifyYourBusinessPage: {
    loadData: VerifyYourBusinessPageLoader,
  },
  InboxPage: {
    loadData: InboxPageLoader,
  },
  ListingPage: {
    loadData: ListingPageLoader,
  },
  SearchPage: {
    loadData: SearchPageLoader,
  },
});

export default getPageDataLoadingAPI;
