import config from '../config';
import { LOCALES } from '../translations/locales';

export const getLocale = () => {
  if (typeof window === 'undefined') {
    return config.locale;
  }
  return window.localStorage.getItem('language_locale') || config.locale;
};

export const getDefaultLocale = host => {
  const currentHost = host || (typeof window !== 'undefined' && window.location.host);
  if (!currentHost) {
    return LOCALES.POLISH;
  }
  return currentHost === 'nextvelo.pl' ||
    currentHost === 'www.nextvelo.pl' ||
    currentHost === 'gearro.pl' ||
    currentHost === 'www.gearro.pl'
    ? LOCALES.POLISH
    : LOCALES.LITHUANIAN;
};

export const getDefaultCountry = host => {
  const currentHost = host || (typeof window !== 'undefined' && window.location.host);
  if (!currentHost) {
    return 'PL';
  }
  return currentHost === 'gearro.pl' || currentHost === 'www.gearro.pl' ? 'PL' : 'LT';
}

export const getDefaultCurrency = (userCountry) => {
  if (userCountry !== undefined && userCountry === 'PL') return 'PLN';
  if (userCountry !== undefined && userCountry === 'LT') return 'EUR';

  const currentHost = typeof window !== 'undefined' && window.location.host;
  if (!currentHost) return 'PLN';
  return currentHost === 'gearro.pl' || currentHost === 'www.gearro.pl' ? 'PLN' : 'EUR';
}
