export const ltTranslations = {
  'banner.secure_payments': '100% saugūs mokėjimai',
  'banner.buyer_protection': 'Pirkėjų apsauga',
  'banner.delivery': 'Greitas ir patogus pristatymas',
  'banner.verified_sellers': 'Verifikuoti pardavėjai',

  'locales.english': 'EN',
  'locales.lithuanian': 'LT',
  'locales.polish': 'PL',
  'months.january': 'Sausio',
  'months.february': 'Vasario',
  'months.march': 'Kovo',
  'months.april': 'Balandžio',
  'months.may': 'Gegužės',
  'months.june': 'Birželio',
  'months.july': 'Liepos',
  'months.august': 'Rugpjūčio',
  'months.september': 'Rugsėjo',
  'months.october': 'Spalio',
  'months.november': 'Lapkričio',
  'months.december': 'Gruodžio',

  'landing_page.sell_button': 'Parduok dabar',
  'landing_page.view_bikes_and_parts': 'Rask dviratį arba dalis',
  'landing_page.buy_bike_button': 'Peržiūrėti visus dviračius',
  'landing_page.buy_parts_button': 'Peržiūrėti visas dalis',
  'landing_page.section_hero.title': 'Iškeisk savo',
  'landing_page.section_hero.sub_title': 'dviratį į pinigus',
  'landing_page.section_hero.description_1': 'Nemokamai sukurk skelbimą vos per 3 minutes',
  'landing_page.section_hero.description_2': 'Pasiek tūkstančius pirkėjų.',
  'landing_page.schema_description':
    'Pirk ir parduok naudotus plentinius, gravel ir kalnų dviračius Gearro – didžiausioje internetinėje naudotų dviračių platformoje. Su pirkėjo apsauga, greitu pristatymu ir saugiais mokėjimais.',
  'Landing_page.schema_title': 'Atrask naudotus MTB, gravel ir elektrinius dviračius',
  'landing_page.categories_label': 'Kategorijos',
  'landing_page.most_recent_label': 'Naujausi',
  'landing_page.most_popular_label': 'Rekomenduojama',
  'landing_page.most_recent_part_label': 'Dviračių dalys',
  'landing_page.popular_brands': 'Populiarūs prekių ženklai',
  'landing_page.view_more': 'Daugiau',
  'landing_page.sell_bike_ad.title': 'Parduok savo dviratį',
  'landing_page.sell_bike_ad.description':
    'Skelbimo procesas yra paprastas ir greitas, pirkėjai greitai reaguoja į tavo pateiktą informaciją.',
  'landing_page.sell_bike_ad.button': 'Parduok dviratį',
  'landing_page.section_reviews.title': 'Užtikriname kokybę ir aptarnavimą',
  'landing_page.section_reviews.subtitle': 'Ir mūsų klientai tai patvirtina',
  'landing_page.section_reviews.verified': 'Verifikuotas pardavėjas',
  'landing_page.section_reviews.customer': 'Klientas',
  'landing_page.grid.gear_fuels_passion': 'Rinkis iš tūkstančių produktų',
  'landing_page.grid.gear_fuels_passion_description': 'Siuntimas šalies viduje - nemokamas!',
  'landing_page.grid.shop_all': 'Žiūrėti viską',
  'landing_page.why_gearro.why': 'Kodėl Gearro?',
  'landing_page.why_gearro.title_1': 'Saugūs mokėjimai',
  'landing_page.why_gearro.description_1': 'Tavo pinigai yra saugomi iki kol patvirtinsi prekės kokybę.',
  'landing_page.why_gearro.title_2': 'Nemokamas siuntimas',
  'landing_page.why_gearro.description_2': 'Siuntimas šalies viduje nemokamas.',
  'landing_page.why_gearro.title_3': 'Patikimi nariai',
  'landing_page.why_gearro.description_3': 'Pirk ir parduok užtikrintai. Visi nariai yra verifikuoti',
  'landing_page.why_gearro.title_4': 'Pirkėjų apsauga',
  'landing_page.why_gearro.description_4': 'Tavo pinigai saugomi iki kol patvirtinsi prekės kokybę.',
  'landing_page.stories.title': 'Naudinga',
  'landing_page.create_listing.create_listing': 'Sukurkite skelbimą per {seconds} sekundes',
  'landing_page.create_listing.import_from_link': 'Importuoti iš nuorodos',
  'landing_page.section_info.label': 'Kaip tai veikia',
  'landing_page.section_info.sell': 'Pardavimas',
  'landing_page.section_info.sell.question_0': 'Papasakok apie parduodamą dviratį',
  'landing_page.section_info.sell.answer_0':
    'Pateik dviračio specifikacijas, įskaitant dydį, medžiagas, spalvą ir kitą informaciją.',
  'landing_page.section_info.sell.question_1': 'Tvarkyk užklausas',
  'landing_page.section_info.sell.answer_1':
    'Bendrauk su potencialiais pirkėjais per žinučių sistemą, atsakyk į jų klausimus arba susitark dėl bandomojo važiavimo.',
  'landing_page.section_info.sell.question_2': 'Patvirtink mokėjimą',
  'landing_page.section_info.sell.answer_2': 'Susitik su pirkėju, kad gautum mokėjimą, arba priimk mokėjimą internetu.',
  'landing_page.section_info.buy': 'Pirkimas',
  'landing_page.section_info.buy.question_0': 'Pasirink patinkantį dviratį',
  'landing_page.section_info.buy.answer_0': 'Ieškok dviračių pagal dydį, tipą ar kitus požymius.',
  'landing_page.section_info.buy.question_1': 'Sužinok daugiau apie dviratį',
  'landing_page.section_info.buy.answer_1': 'Susitik ir išbandyk dviratį. Arba, jei nori, gauk jį iš karto.',
  'landing_page.section_info.buy.question_2': 'Patvirtink mokėjimą',
  'landing_page.section_info.buy.answer_2':
    'Susitik su pardavėju ir sumokėk grynaisiais pinigais arba susitark dėl mokėjimo internetu.',
  'landing_page.section_info.sell.title_0': '1. Įtrauk į sąrašą nemokamai',
  'landing_page.section_info.sell.description_0':
    'Įtraukimo į sąrašą procesas yra paprastas ir greitas. Nufotografuok daiktą, aprašyk jį ir nustatyk kainą.',
  'landing_page.section_info.sell.title_1': '2. Parduok ir išsiųsk.',
  'landing_page.section_info.sell.description_1':
    'Parduota! Supakuok dviratį, atsispausdink iš anksto apmokėtą siuntimo etiketę ir paprašyk kurjerio atsiimti dviratį per 5 dienas.',
  'landing_page.section_info.sell.title_2': '3. Atėjo mokėjimo diena!',
  'landing_page.section_info.sell.description_2':
    'Pardavimo mokesčiai netaikomi, todėl viskas, ką uždirbai, lieka tau. Apmokėjimą gausi, kai pirkėjas patikrins, ar viskas tvarkoje.',
  'landing_page.section_info.buy.title_0': '1. Rask dviratį',
  'landing_page.section_info.buy.description_0':
    'Peržiūrėk tūkstančius dviračių, ieškok pagal prekės ženklą ar kitas specifikacijas ir atrask sau tinkamiausią dviratį.',
  'landing_page.section_info.buy.title_1': '2. Pirk',
  'landing_page.section_info.buy.description_1':
    'Užduok pardavėjui klausimus, tada pirk vienu mygtuko paspaudimu. Saugiai mokėk per Stripe, banko kortele arba Apple Pay.',
  'landing_page.section_info.buy.title_2': '3. Gauk',
  'landing_page.section_info.buy.description_2':
    'Užsakydamas matysi numatomą pristatymo datą, o užsakymas bus pristatytas tiesiai į tavo namus.',
  'landing_page.section_hero.slide_1.title': 'Parduok savo naudotą dviratį nemokamai',
  'landing_page.section_hero.slide_1.content': 'Įkelk skelbimą nemokamai per mažiau nei minutę.',
  'landing_page.section_hero.slide_1.button': 'Parduok dabar',
  'landing_page.section_hero.slide_2.title': '#1 naudotos sporto įrangos turgavietė',
  'landing_page.section_hero.slide_2.content': 'Sutaupyk vidutiniškai 30%, pirkdamas naudotą sporto inventorių.',
  'landing_page.section_hero.slide_2.button': 'Pirkti dabar',
  'landing_page.section_hero.slide_3.title': 'Gauk norimą jausmą už mažiau',
  'landing_page.section_hero.slide_3.content': 'Sutaupyk vidutiniškai 30%, pirkdamas naudotą sporto įrangą.',
  'landing_page.section_hero.slide_3.button': 'Žiūrėti viską',
  'landing_page.section_hero.slide_winter.title': 'Parduok savo slidinėjimo ir snieglenčių įrangą',
  'landing_page.section_hero.slide_winter.content': 'Parduok naudotą žiemos inventorių',
  'landing_page.section_hero.slide_winter.button': 'Parduok dabar',
  'landing_page.section_hero.slide_winter_2.title': 'Ar esi pasiruošęs žiemai?',
  'landing_page.section_hero.slide_winter_2.content': 'Pirk naudotą žiemos inventorių',
  'landing_page.section_hero.slide_winter_2.button': 'Pirkti dabar',

  'config.labels.condition': 'Būklė',
  'config.labels.shipping': 'Pristatymas',
  'config.labels.receiptAvailable': 'Kvitas',
  'config.labels.refurbished': 'Atnaujinta',
  'config.labels.frameColor': 'Spalva',
  'config.labels.frameMaterial': 'Medžiaga',
  'config.labels.wheelSize': 'Ratų dydis',
  'config.labels.frameSize': 'Rėmo dydis',
  'config.labels.year': 'Metai',
  'config.labels.brand': 'Prekės ženklas',
  'config.labels.category': 'Tipas',
  'config.labels.price': 'Kaina',
  'config.labels.keyword': 'Raktažodis',
  'config.labels.model': 'Modelis',
  'config.labels.country': 'Šalis',
  'config.labels.subCategory': 'Kategorija',
  'config.labels.discipline': 'Disciplina',
  'config.labels.frontTravel': 'Priekinės šakės eiga',
  'config.labels.rearTravel': 'Galinės šakės eiga',
  'config.labels.dropperTravel': 'Eiga',
  'config.labels.dropperDiameter': 'Skersmuo',
  'config.labels.dropperActivation': 'Aktyvavimas',
  'config.labels.catalogTree': 'Kategorija',
  'config.labels.hubStandard': 'Stebulės standartas',
  'config.labels.frontHubStandard': 'Priekinės stebulės standartas',
  'config.labels.rearHubStandard': 'Galinės stebulės standartas',
  'config.labels.suspensionType': 'Amortizacijos tipas',
  'config.labels.suspensionMounting': 'Amortizacijos montavimas',
  'config.labels.suspensionLength': 'Amortizacijos ilgis',
  'config.labels.suspensionStroke': 'Amortizacijos eiga',
  'config.labels.axleDimension': 'Ašies matmenys',
  'config.labels.gears': 'Pavaros',
  'config.labels.frontGears': 'Priekinės pavaros',
  'config.labels.rearGears': 'Galinės pavaros',
  'config.labels.placement': 'Tipas',
  'config.labels.activation': 'Aktyvavimas',
  'config.labels.chainringMounting': 'Grandinės žvaigždžių tvirtinimas',
  'config.labels.bottomBracketMounting': 'Tvirtinimas',
  'config.labels.pedals': 'Tipas',
  'config.labels.frontWheelSize': 'Priekinio rato dydis',
  'config.labels.rearWheelSize': 'Galinio rato dydis',
  'config.labels.wheelsPlacement': 'Tipas',
  'config.labels.brakeType': 'Tipas',
  'config.labels.brakesActivation': 'Aktyvavimas',
  'config.labels.hubsPlacement': 'Tipas',
  'config.labels.handlebarType': 'Tipas',
  'config.labels.gripsType': 'Tipas',
  'config.labels.steererType': 'Tipas',
  'config.labels.crankArmLength': 'Švaistiklio ilgis',
  'config.labels.chainringTeeth': 'Dantys',
  'config.labels.handlebarWidth': 'Vairo plotis',

  'top_bar.categories.all_bikes': 'Visi dviračiai',
  'top_bar.categories.mountain': 'Kalnų dviračiai',
  'top_bar.categories.road': 'Plentiniai dviračiai',
  'top_bar.categories.gravel': 'Gravel dviračiai',
  'top_bar.categories.enduro': 'Enduro dviračiai',
  'top_bar.categories.parts': 'Dalys',
  'top_bar.categories.winter': 'Žiemos įranga',

  'categories.all': 'Visos kategorijos',
  'categories.all_bikes': 'Visi dviračiai',
  'categories.none': 'Visos kategorijos',
  'categories.bike': 'Dviračiai',
  'categories.parts': 'Dalys',
  'categories.city': 'Miesto',
  'categories.road': 'Plento',
  'categories.mountain': 'Kalnų',
  'categories.gravel': 'Gravel',
  'categories.bmx_dirt': 'BMX & Dirt',
  'categories.folding': 'Sulankstomi',
  'categories.electric': 'Elektriniai',
  'categories.enduro': 'Enduro',
  'categories.downhill': 'Downhill',
  'categories.snowboard': 'Snieglentės',
  'categories.other': 'Kita',
  'categories.track': 'Trekas',
  'categories.aearo_triathlon_tt': 'Aero/Triatlonas/TT',
  'categories.single_gear': 'Vienos pavaros',
  'categories.xc_cross_country': 'XC / Cross Country',
  'categories.trail': 'Trail',
  'categories.bmx': 'BMX',
  'categories.dirt_street': 'Dirt/Street dviračiai',
  'categories.electric_city': 'Miestas',
  'categories.electric_mountain': 'Kalnų',
  'categories.electric_road': 'Plentiniai',
  'categories.electric_other': 'Kita',
  'categories.cargo': 'Krovininiai dviračiai',
  'categories.tricycle': 'Triračiai',
  'categories.tandem': 'Tandemai',
  'categories.balance': 'Balansiniai dviračiai',
  'categories.unicycle': 'Vienaračiai',
  'categories.frame': 'Rėmas',
  'categories.suspension': 'Amortizacija',
  'categories.drivetrain': 'Pavarų sistema',
  'categories.brakes': 'Stabdžiai',
  'categories.wheels': 'Ratai',
  'categories.tyres_tubes': 'Padangos',
  'sub_categories.tyres_tubes': 'Padangos',
  'categories.cockpit': 'Vairas',
  'categories.seat': 'Sėdynė',
  'sub_categories.frame_full_suspension': 'Pilnos amortizacijos',
  'sub_categories.frame_hard_tail_mtb': 'Hardtail MTB',
  'sub_categories.frame_road': 'Plentiniai',
  'sub_categories.frame_gravel': 'Gravel',
  'sub_categories.frame_full_suspension_e_bike': 'Pilnos amortizacijos E-dviratis',
  'sub_categories.frame_hard_tail_e_bike': 'Hardtail E-dviratis',
  'sub_categories.frame_city': 'Miestiniai',
  'sub_categories.frame_dirt_n_street': 'Dirt & Street',
  'sub_categories.suspension_rigid_fork': 'Standi šakė',
  'sub_categories.suspension_suspension_fork': 'Amortizacinė šakė',
  'sub_categories.suspension_rear': 'Galinė amortizacija',
  'sub_categories.drivetrain_crank': 'Švaistiklis',
  'sub_categories.drivetrain_chain': 'Grandinė',
  'sub_categories.drivetrain_cassette': 'Kasetė',
  'sub_categories.drivetrain_shifter': 'Pavarų perjungiklis',
  'sub_categories.drivetrain_derailleur': 'Pavarų mechanizmas',
  'sub_categories.drivetrain_chainring': 'Grandinės žiedas',
  'sub_categories.drivetrain_bottom_bracket': 'Centruotė',
  'sub_categories.drivetrain_chain_guides': 'Grandinės vediklis',
  'sub_categories.drivetrain_pedals': 'Pedalai',
  'sub_categories.brakes_disk': 'Diskiniai stabdžiai',
  'sub_categories.brakes_rim': 'Ratlankiniai stabdžiai',
  'sub_categories.brakes_rotor': 'Stabdžių diskas',
  'sub_categories.brakes_other': 'Kita',
  'sub_categories.wheels_complete': 'Pilni ratai',
  'sub_categories.wheels_hubs': 'Stebulės',
  'sub_categories.wheels_rims': 'Ratlankiai',
  'sub_categories.cockpit_handlebar': 'Vairas',
  'sub_categories.cockpit_stem': 'Vairo iškyša',
  'sub_categories.cockpit_grips': 'Rankenos',
  'sub_categories.cockpit_headset': 'Vairo kolonėlė',
  'sub_categories.seat_dropper_post': 'Nuleidžiama sėdynės stulpelis',
  'sub_categories.seat_post': 'Sėdynės stulpelis',
  'sub_categories.seat_saddle': 'Sėdynė',
  'sub_categories.seat_other': 'Kita',
  'new_listing_page.type.winter': 'Žiemos įranga',
  'new_listing_page.description.winter': 'Snieglentės, slidės, batai ir aksesuarai',
  'categories.winter': 'Žiemos įranga',
  'categories.skis': 'Slidės',
  'categories.accessories': 'Aksesuarai',
  'sub_categories.skis_alpine': 'Alpinistinės slidės',
  'sub_categories.skis_touring_backcountry': 'Turistinės slidės',
  'sub_categories.skis_cross_country': 'Lygumų slidės',
  'sub_categories.skis_park_pipe': 'Parko vamzdžiai',
  'sub_categories.skis_boots': 'Batai',
  'sub_categories.skis_bindings': 'Apkaustai',
  'sub_categories.skis_poles': 'Lazdos',
  'sub_categories.snowboard': 'Snieglentė',
  'sub_categories.snowboard_boots': 'Batai',
  'sub_categories.snowboard_bindings': 'Apkaustai',
  'sub_categories.helmets': 'Šalmas',
  'sub_categories.goggles': 'Akiniai',
  'parts_listing_page.specifications.ski_length': 'Slidžių ilgis',
  'parts_listing_page.specifications.ski_length_placeholder': 'Pasirink slidžių ilgį',
  'parts_listing_page.specifications.ski_length_required': 'Privalai pasirinkti slidžių ilgį',
  'parts_listing_page.specifications.ski_waist_width': 'Juosmens plotis',
  'parts_listing_page.specifications.ski_waist_width_placeholder': 'Pasirink juosmens plotį',
  'parts_listing_page.specifications.with_bindings': 'Su apkaustais',
  'parts_listing_page.specifications.with_bindings_placeholder': 'Pasirink',
  'parts_listing_page.specifications.gender': 'Lytis',
  'parts_listing_page.specifications.gender_placeholder': 'Pasirink lytį',
  'parts_listing_page.specifications.snowboard_size': 'Dydis',
  'parts_listing_page.specifications.snowboard_size_placeholder': 'Pasirink snieglentės dydį',
  'parts_listing_page.specifications.snowboard_size_required': 'Privalai pasirinkti snieglentės dydį',
  'parts_listing_page.specifications.snowboard_type': 'Snieglentės tipas',
  'parts_listing_page.specifications.snowboard_type_placeholder': 'Pasirink snieglentės tipą',
  'parts_listing_page.specifications.snowboard_flex_rate': 'Lankstumo lygis',
  'parts_listing_page.specifications.snowboard_flex_rate_placeholder': 'Pasirink lankstumo lygį',

  'parts_listing_page.specifications.helmet_size': 'Šalmo dydis',
  'parts_listing_page.specifications.helmet_size_placeholder': 'Pasirink šalmo dydį',
  'parts_listing_page.specifications.helmet_size_required': 'Šalmo dydis privalomas',
  'parts_listing_page.specifications.color': 'Spalva',
  'parts_listing_page.specifications.color_placeholder': 'Pasirink spalvą',
  'parts_listing_page.specifications.goggles_frame_size': 'Rėmo dydis',
  'parts_listing_page.specifications.goggles_frame_size_placeholder': 'Pasirink rėmo dydį',
  'parts_listing_page.specifications.goggles_frame_size_required': 'Privalai pasirinkti rėmo dydį',
  'parts_listing_page.specifications.poles_size': 'Lazdų dydis',
  'parts_listing_page.specifications.poles_size_placeholder': 'Pasirink lazdų dydį',
  'parts_listing_page.specifications.boots_size': 'Batų dydis',
  'parts_listing_page.specifications.boots_size_placeholder': 'Pasirink batų dydį',
  'parts_listing_page.specifications.boots_size_required': 'Privalai pasirinkti batų dydį',
  'parts_listing_page.specifications.ski_boots_type': 'Batų tipas',
  'parts_listing_page.specifications.ski_boots_type_placeholder': 'Pasirink batų tipą',
  'parts_listing_page.specifications.ski_boots_flex_rate': 'Lankstumo lygis',
  'parts_listing_page.specifications.ski_boots_flex_rate_placeholder': 'Pasirink lankstumo lygį',
  'parts_listing_page.specifications.ski_bindings_size': 'Apkaustų dydis',
  'parts_listing_page.specifications.ski_bindings_size_placeholder': 'Pasirink apkaustų dydį',
  'parts_listing_page.specifications.ski_bindings_size_required': 'Privalai pasirinkti apkaustų dydį',
  'parts_listing_page.specifications.snowboard_boots_flex_rate': 'Batų lankstumo lygis',
  'parts_listing_page.specifications.snowboard_boots_flex_rate_placeholder': 'pasirink batų lankstumo lygį',
  'parts_listing_page.specifications.snowboard_bindings_size': 'Apkaustų dydis',
  'parts_listing_page.specifications.snowboard_bindings_size_placeholder': 'Pasirink apkaustų dydį',
  'parts_listing_page.specifications.snowboard_bindings_size_required': 'Privalai pasirinkti apkaustų dydį',
  'gender.men': 'Vyrai',
  'gender.women': 'Moterys',
  'gender.kids': 'Vaikai',
  'gender.unisex': 'Unisex',
  'gender.placeholder': 'Pasirink lytį',
  'with_bindings.yes': 'Taip',
  'with_bindings.no': 'Ne',
  'with_bindings.placeholder': 'Pasirink apkaustus',
  'snowboard_type.all_mountain': 'Visi',
  'snowboard_type.freeride': 'Laisvasis nusileidimas',
  'snowboard_type.freestyle': 'Freestyle',
  'snowboard_type.park_pipe': 'Parkas & Vamzdis',
  'snowboard_type.powder': 'Purvasniegis',
  'snowboard_type.splitboards': 'Splitboardai',
  'snowboard_type.wide': 'Plati',
  'snowboard_type.placeholder': 'Pasirink tipą',
  'snowboard_flex_rate.soft': 'Minkštas',
  'snowboard_flex_rate.medium': 'Vidutinis',
  'snowboard_flex_rate.stiff': 'Standus',
  'snowboard_flex_rate.very_stiff': 'Labai standus',
  'snowboard_flex_rate.placeholder': 'Pasirink lankstumo lygį',
  'goggles_frame_size.placeholder': 'Pasirink rėmo dydį',
  'goggles_frame_size.small': 'Mažas',
  'goggles_frame_size.medium': 'Vidutinis',
  'goggles_frame_size.large': 'Didelis',
  'snowboard_size.placeholder': 'Pasirink dydį',
  'boots_size.placeholder': 'Pasirink dydį',
  'snowboard_boots_flex_rate.soft': 'Minkštas',
  'snowboard_boots_flex_rate.medium': 'Vidutinis',
  'snowboard_boots_flex_rate.adjustable': 'Reguliuojamas',
  'snowboard_boots_flex_rate.placeholder': 'Pasirink lankstumo lygį',
  'snowboard_bindings_size.xs': 'Labai mažas',
  'snowboard_bindings_size.s': 'Mažas',
  'snowboard_bindings_size.m': 'Vidutinis',
  'snowboard_bindings_size.l': 'Didelis',
  'snowboard_bindings_size.xl': 'Labai didelis',
  'snowboard_bindings_size.one_size': 'Vienas dydis',
  'snowboard_bindings_size.other': 'Kita',
  'snowboard_bindings_size.placeholder': 'Pasirink dydį',
  'helmet_size.placeholder': 'Pasirink dydį',
  'color.placeholder': 'Pasirink spalvą',
  'ski_length.placeholder': 'Pasirink ilgį',
  'ski_waist_width.placeholder': 'Pasirink juosmens plotį',
  'ski_boots_type.alpine': 'Alpinizmas',
  'ski_boots_type.alpine_touring_backcountry': 'Alpinistinės/turistinės slidės',
  'ski_boots_type.cross_country': 'Lygumų slidinėjimas',
  'ski_boots_type.park_pipe': 'Parko vamzdis',
  'ski_boots_type.placeholder': 'Pasirink tipą',
  'ski_boots_flex_rate.very_soft': 'Labai minkštas (mažiau nei 70)',
  'ski_boots_flex_rate.soft': 'Minkštas (70-90)',
  'ski_boots_flex_rate.medium': 'Vidutinis (95-115)',
  'ski_boots_flex_rate.stiff': 'Standus (120-130)',
  'ski_boots_flex_rate.very_stiff': 'Labai standus (daugiau nei 130)',
  'ski_boots_flex_rate.placeholder': 'Pasirink lankstumo lygį',
  'ski_bindings_size.placeholder': 'Pasirink apkaustų dydį',
  'poles_size.adjustable': 'Reguliuojamas',
  'poles_size.34': '34 coliai (85 cm)',
  'poles_size.36': '36 coliai (90 cm)',
  'poles_size.38': '38 coliai (95 cm)',
  'poles_size.40': '40 coliai (100 cm)',
  'poles_size.42': '42 coliai (105 cm)',
  'poles_size.44': '44 coliai (110 cm)',
  'poles_size.46': '46 coliai (115 cm)',
  'poles_size.48': '48 coliai (120 cm)',
  'poles_size.50': '50 colių (125 cm)',
  'poles_size.52': '52 coliai (130 cm)',
  'poles_size.54': '54 coliai (135 cm)',
  'poles_size.56': '56 coliai (140 cm)',
  'poles_size.more_than_56': 'Daugiau nei 56 coliai (140 cm)',
  'poles_size.other': 'Kita',
  'poles_size.placeholder': 'Pasirink dydį',
  'config.labels.snowboardSize': 'Dydis',
  'config.labels.snowboardType': 'Tipas',
  'config.labels.snowboardFlexRate': 'Lankstumo lygis',
  'config.labels.withBindings': 'Su apkaustais',
  'config.labels.gender': 'Lytis',
  'config.labels.bootsSize': 'Batų dydis',
  'config.labels.snowboardBootsFlexRate': 'Batų lankstumo lygis',
  'config.labels.snowboardBindingsSize': 'Apkaustų dydis',
  'config.labels.helmetSize': 'Šalmo dydis',
  'config.labels.gogglesFrameSize': 'Rėmo dydis',
  'config.labels.skiLenght': 'Ilgis',
  'config.labels.skiWaistWidth': 'Juosmens plotis',
  'config.labels.skiBootsType': 'Batų tipas',
  'config.labels.skiBootsFlexRate': 'Batų lankstumo lygis',
  'config.labels.skiBindingsSize': 'Apkaustų dydis',
  'config.labels.color': 'Spalva',
  'config.labels.polesSize': 'Lazdų dydis',
  'year.placeholder': 'Pasirink metus',
  'frame_size.placeholder': 'Pasirink rėmo dydį',
  'brand.placeholder': 'Pasirink prekės ženklą',
  'country.placeholder': 'Pasirink šalį',
  'front_travel.placeholder': 'Pasirink priekinę eigą',
  'rear_travel.placeholder': 'Pasirink galinę eigą',
  'country.LT': 'Lietuva',
  'country.LV': 'Latvija',
  'country.EE': 'Estija',
  'country.PL': 'Lenkija',
  'country.all': 'Visos',
  'sub_categories.road': 'Plentas',
  'sub_categories.track': 'Trekas',
  'sub_categories.aearo_triathlon_tt': 'Aero/Triatlonas/TT',
  'sub_categories.single_gear': 'Vienos pavaros',
  'sub_categories.xc_cross_country': 'XC / Cross Country',
  'sub_categories.trail': 'Trail',
  'sub_categories.enduro': 'Enduro',
  'sub_categories.downhill': 'Downhill',
  'sub_categories.bmx': 'BMX',
  'sub_categories.dirt_street': 'Dirt/Street dviračiai',
  'sub_categories.electric_city': 'Miestas',
  'sub_categories.electric_mountain': 'Kalnų',
  'sub_categories.electric_road': 'Plentas',
  'sub_categories.electric_other': 'Kita',
  'sub_categories.cargo': 'Krovininiai',
  'sub_categories.tricycle': 'Triračiai',
  'sub_categories.tandem': 'Tandemai',
  'sub_categories.balance': 'Balansiniai',
  'sub_categories.unicycle': 'Vienaračiai',
  'shipping.no_shipping': 'Tik vietinis atsiėmimas',
  'shipping.domestic_shipping': 'Siuntimas tik šalyje',
  'shipping.international_shipping': 'Siuntimas į užsienį',
  'frame_material.aluminum': 'Aliuminis',
  'frame_material.carbon': 'Anglies pluoštas',
  'frame_material.steel': 'Plienas',
  'frame_material.other': 'Kita',
  'color.pink': 'Rožinė',
  'color.green': 'Žalia',
  'color.orange': 'Oranžinė',
  'color.blue': 'Mėlyna',
  'color.red': 'Raudona',
  'color.white': 'Balta',
  'color.yellow': 'Geltona',
  'color.black': 'Juoda',
  'color.grey': 'Pilka',
  'color.other': 'Kita',
  'wheel_size.16': '16″',
  'wheel_size.20': '20″',
  'wheel_size.28': '28″ (700c)',
  'wheel_size.26': '26″',
  'wheel_size.27.5': '27.5″ (650b)',
  'wheel_size.29': '29″',
  'wheel_size.650B': '650B',
  'wheel_size.700C': '28″ (700c)',
  'wheel_size.other': 'Kita',
  'suspension_mounting.trunnion': 'Trunnion',
  'suspension_mounting.standard': 'Standartinis',
  'discipline.xc_marathon': 'XC / Maratonas',
  'discipline.trail_all_mountain': 'Trail / All-Mountain',
  'discipline.enduro': 'Enduro',
  'discipline.downhill': 'Downhill',
  'hub_standard.15_110': '15 x 110 mm (Boost)',
  'hub_standard.20_110': '20 x 110 mm (Boost)',
  'hub_standard.9_100': '9 x 100 mm',
  'hub_standard.15_100': '15 x 100 mm',
  'hub_standard.20_100': '20 x 100 mm',
  'hub_standard.9_135': '9 x 135 mm',
  'hub_standard.10_135': '10 x 135 mm',
  'hub_standard.15_142': '15 x 142 mm',
  'hub_standard.15_150': '15 x 150 mm',
  'hub_standard.10_170': '10 x 170 mm',
  'hub_standard.12_190': '12 x 190 mm',
  'hub_standard.12_197': '12 x 197 mm',
  'suspension_type.air': 'Oro',
  'suspension_type.coil': 'Spiralė',
  'axle_dimension.30': '30 mm',
  'axle_dimension.29_9': '29.9 mm',
  'axle_dimension.22_24': '22/24 mm (DUB)',
  'axle_dimension.25': '25 mm',
  'axle_dimension.24': '24 mm',
  'axle_dimension.19': '19 mm',
  'axle_dimension.square_taper': 'Kvadratinis kūginis',
  'axle_dimension.octalink': 'Octalink',
  'axle_dimension.m30': 'M30',
  'axle_dimension.isis': 'ISIS',
  'axle_dimension.american': 'American',
  'axle_dimension.mid': 'Mid',
  'gears.1': '1',
  'gears.2': '2',
  'gears.3': '3',
  'gears.4': '4',
  'gears.5': '5',
  'gears.6': '6',
  'gears.7': '7',
  'gears.8': '8',
  'gears.9': '9',
  'gears.10': '10',
  'gears.11': '11',
  'gears.12': '12',
  'gears.13': '13',
  'placement.front': 'Priekinis',
  'placement.rear': 'Galinė',
  'placement.set': 'Komplektas',
  'activation.mechanical': 'Mechaninis',
  'activation.electric': 'Elektrinis',
  'chainring_mounting.3_arm': '3-arm',
  'chainring_mounting.4_arm': '4-arm',
  'chainring_mounting.5_arm': '5-arm',
  'bottom_bracket_mounting.bsa': 'BSA',
  'bottom_bracket_mounting.ita': 'ITA',
  'bottom_bracket_mounting.pressfit': 'Pressfit',
  'pedals.flat': 'Plokščias',
  'pedals.clipless': 'Be užsegimo',
  'pedals.hybrid': 'Hibridinis',
  'brakes_activation.hydraulic': 'Hidraulinis',
  'brakes_activation.mechanical': 'Mechaninis',
  'handlebar_type.mtb': 'MTB',
  'handlebar_type.dropbar': 'Dropbar',
  'handlebar_type.bmx': 'BMX',
  'handlebar_type.city_trekking': 'Miestas/Trekingas',
  'grips_type.lock_on': 'Fiksuojamos rankenos',
  'grips_type.push_on': 'Stumiamos rankenos',
  'grips_type.bar_tape': 'Vairo juosta',
  'steerer_type.1_5': '1.5”',
  'steerer_type.1_8': '1-1/8”',
  'steerer_type.threaded': 'Sriegis',
  'steerer_type.tapered': 'Kūginis',
  'dropper_diameter.27_2': '27.2 mm',
  'dropper_diameter.28_6': '28.6 mm',
  'dropper_diameter.30_9': '30.9 mm',
  'dropper_diameter.31_6': '31.6 mm',
  'dropper_diameter.31_8': '31.8 mm',
  'dropper_diameter.34_9': '34.9 mm',
  'dropper_activation.mechanical': 'Mechaninis',
  'dropper_activation.electric': 'Elektrinis',
  'dropper_activation.hydraulic': 'Hidraulinis',
  'rotor_mounting.6_hole': '6 skylės',
  'rotor_mounting.centerlock': 'Centerlock',
  'rotor_placement.front': 'Priekinis',
  'rotor_placement.rear': 'Galinė',
  'rotor_placement.set': 'Komplektas',
  'wheels_placement.front': 'Priekinis',
  'wheels_placement.rear': 'Galinė',
  'wheels_placement.set': 'Komplektas',
  'hubs_placement.front': 'Priekinis',
  'hubs_placement.rear': 'Galinė',
  'hubs_placement.set': 'Komplektas',
  'receipt.yes': 'Taip',
  'receipt.no': 'Ne',
  'refurbished.yes': 'Taip',
  'refurbished.no': 'Ne',
  'condition.bad': 'Dviračio dalys',
  'condition.partly_bad': 'Reikia remonto',
  'condition.good': 'Gera būklė',
  'condition.perfect': 'Puiki būklė',
  'condition.brand_new': 'Visiškai naujas',
  'condition.bad.label': '✔ Netinkamas važiuoti\\n✔ Reikalingas patikrinimas ir remontas\\n✔ Būtina keisti dalis',
  'condition.partly_bad.label':
    '✔ Paviršiniai įbrėžimai\\n✔ Reikalingas serviso reguliavimas\\n✔ Gali reikėti keisti kai kurias dalis',
  'condition.good.label': '✔ Paviršiniai įbrėžimai\\n✔ Gali reikėti serviso reguliavimo\\n✔ Dalys nereikalauja keitimo',
  'condition.perfect.label': '✔ Nedideli įbrėžimai\\n✔ Mechaninis tobulas\\n✔ Nėra reikšmingos žalos komponentams',
  'condition.brand_new.label': '✔ Dviratis niekada nebuvo naudotas',
  'condition.used_part': 'Naudotas',
  'condition.good_part': 'Geras',
  'condition.new_part': 'Naujas',
  'condition.used_part.label': '✔ Anksčiau naudotas, su matomais trūkumais.',
  'condition.good_part.label': '✔ Mažai naudotas, puikios būklės.',
  'condition.new_part.label': '✔ Puikios būklės, niekada nenaudotas.',
  'frame_size.xxl': 'XXL',
  'frame_size.xl': 'XL',
  'frame_size.l_xl': 'L/XL',
  'frame_size.l': 'L',
  'frame_size.m_l': 'M/L',
  'frame_size.m': 'M',
  'frame_size.s_m': 'S/M',
  'frame_size.s': 'S',
  'frame_size.xs': 'XS',
  'frame_size.xxs': 'XSS',
  'front_travel.60': '60',
  'front_travel.70': '70',
  'front_travel.80': '80',
  'front_travel.90': '90',
  'front_travel.100': '100',
  'front_travel.110': '110',
  'front_travel.120': '120',
  'front_travel.130': '130',
  'front_travel.140': '140',
  'front_travel.150': '150',
  'front_travel.160': '160',
  'front_travel.170': '170',
  'front_travel.180': '180',
  'front_travel.190': '190',
  'front_travel.200': '200',
  'front_travel.210': '210',
  'front_travel.220': '220',
  'front_travel.230': '230',
  'rear_travel.60': '60',
  'rear_travel.70': '70',
  'rear_travel.80': '80',
  'rear_travel.90': '90',
  'rear_travel.100': '100',
  'rear_travel.110': '110',
  'rear_travel.120': '120',
  'rear_travel.130': '130',
  'rear_travel.140': '140',
  'rear_travel.150': '150',
  'rear_travel.160': '160',
  'rear_travel.170': '170',
  'rear_travel.180': '180',
  'rear_travel.190': '190',
  'rear_travel.200': '200',
  'rear_travel.210': '210',
  'rear_travel.220': '220',
  'rear_travel.230': '230',
  'top_bar.search_form.placeholder': 'Paieška',
  'top_bar.generic_error_message': 'Įvyko klaida. Bandykite dar kartą.',
  'top_bar.logo_icon_label': 'Eiti į pagrindinį puslapį',
  'top_bar.menu_icon_label': 'Atidaryti meniu',
  'top_bar.under_menu_bar_text':
    '<span style="font-size: inherit; font-weight: 600; text-decoration: underline; color: inherit">Parduok</span> sporto įrangą ir laimėk 200 EUR kuponą!',
  'top_bar.log_in': 'Prisijungti',
  'top_bar.log_out': 'Atsijungti',
  'top_bar.sell': 'Parduoti dabar',
  'top_bar.buy': 'Pirkti',
  'top_bar.inbox_label': 'Gautieji',
  'top_bar.listings_label': 'Tavo skelbimai',
  'top_bar.profile_settings_label': 'Profilio nustatymai',
  'top_bar.account_settings_label': 'Nustatymai',
  'top_bar.profile': 'Profilis',
  'top_bar.purchases': 'Pirkimai',
  'top_bar.sales': 'Pardavimai',
  'top_bar.mobile_menu.buy': 'Pirkti',
  'top_bar.mobile_menu.about_page': 'Apie',
  'top_bar.mobile_menu.how_it_works_page': 'Kaip tai veikia',
  'top_bar.mobile_menu.show_profile': 'Rodyti profilį',
  'top_bar.mobile_menu.account_label': 'Paskyra',
  'top_bar.mobile_menu.services_label': 'Paslaugos',
  'top_bar.mobile_menu.favorite_listings': 'Mėgstamiausi skelbimai',
  'top_bar.mobile_menu.explore': 'Atrask',
  'top_bar.mobile_menu.all_categories': 'Visos kategorijos',
  'top_bar.mobile_menu.terms_of_service': 'Paslaugų teikimo sąlygos',
  'top_bar.mobile_menu.privacy_policy': 'Privatumo politika',
  'top_bar.desktop.logo_label': 'Gearro',
  'authentication_page.or': 'arba',
  'authentication_page.facebook_login': 'Tęsti su Facebook',
  'authentication_page.google_login': 'Tęsti su Google',
  'authentication_page.email_login': 'Tęsti su el. paštu',
  'authentication_page.terms_and_conditions_accept_label':
    'Pasirinkdamas „Sutinku ir tęsti“, sutinku su Gearro {termsLink} ir {privacyPolicyLink}',
  'authentication_page.terms_and_conditions_label': 'Paslaugų teikimo sąlygos',
  'authentication_page.privacy_policy_label': 'Privatumo politika',
  'log_in.email_label': 'El. paštas',
  'log_in.email_placeholder': 'El. paštas',
  'log_in.email_required': 'Įveskite el. paštą',
  'log_in.email_invalid': 'Pateiktas el. paštas yra neteisingas',
  'log_in.password_label': 'Slaptažodis',
  'log_in.password_placeholder': 'Slaptažodis',
  'log_in.password_required': 'Įveskite slaptažodį',
  'log_in.forgot_password': 'Pamiršote slaptažodį?',
  'log_in.log_in_label': 'Prisijungti',
  'log_in.sign_up_prompt_part_1': 'Neturite paskyros?',
  'log_in.sign_up_label': 'Registruotis',
  'log_in.sign_up_prompt_part_2': 'kaip individualus asmuo arba',
  'log_in.business_sign_up_label': 'Verslo partneris',
  'log_in.failed':
    'Jūsų įvestas el. paštas ir slaptažodis neatitinka mūsų duomenų. Patikrinkite ir bandykite dar kartą.',
  'sign_up.email_label': 'El. paštas',
  'sign_up.email_placeholder': 'El. paštas',
  'sign_up.email_required': 'Įveskite el. paštą',
  'sign_up.email_invalid': 'Pateiktas el. paštas yra neteisingas',
  'sign_up.password_label': 'Slaptažodis',
  'sign_up.password_placeholder': 'Slaptažodis',
  'sign_up.password_required': 'Įveskite slaptažodį',
  'sign_up.password_too_short': 'Slaptažodis turi būti bent {minLength} simbolių',
  'sign_up.password_too_long': 'Slaptažodis gali būti daugiausia {maxLength} simbolių',
  'sign_up.first_name_label': 'Vardas',
  'sign_up.first_name_placeholder': 'Vardas',
  'sign_up.first_name_required': 'Įveskite vardą',
  'sign_up.last_name_label': 'Pavardė',
  'sign_up.last_name_placeholder': 'Pavardė',
  'sign_up.last_name_required': 'Įveskite pavardę',
  'sign_up.user_name_label': 'Vartotojo vardas',
  'sign_up.user_name_placeholder': 'Vartotojo vardas',
  'sign_up.user_name_required': 'Įveskite vartotojo vardą',
  'sign_up.sign_up_label': 'Registruotis',
  'sign_up.business.sign_up_label': 'Sukurkite savo partnerio paskyrą',
  'sign_up.log_in_prompt': 'Jau turite paskyrą?',
  'sign_up.log_in_label': 'Prisijungti',
  'sign_up.failed':
    'Registracija nepavyko. Įsitikinkite, kad visi įvesti duomenys yra teisingi, ir bandykite dar kartą.',
  'sign_up.email_already_taken': 'El. paštas jau naudojamas',
  'confirm_sign_up.email_label': 'El. paštas',
  'confirm_sign_up.email_placeholder': 'El. paštas',
  'confirm_sign_up.email_required': 'Įveskite el. paštą',
  'confirm_sign_up.email_invalid': 'Pateiktas el. paštas yra neteisingas',
  'confirm_sign_up.first_name_label': 'Vardas',
  'confirm_sign_up.first_name_placeholder': 'Vardas',
  'confirm_sign_up.first_name_required': 'Įveskite vardą',
  'confirm_sign_up.last_name_label': 'Pavardė',
  'confirm_sign_up.last_name_placeholder': 'Pavardė',
  'confirm_sign_up.last_name_required': 'Įveskite pavardę',
  'confirm_sign_up.user_name_label': 'Vartotojo vardas',
  'confirm_sign_up.user_name_placeholder': 'Vartotojo vardas',
  'confirm_sign_up.user_name_required': 'Įveskite vartotojo vardą',
  'confirm_sign_up.confirm_and_continue_label': 'Sutinku ir tęsti',
  'terms_of_service_page.schema_title': 'Paslaugų teikimo sąlygos | {siteTitle}',
  'avatar.banned_user_label': 'Užblokuotas vartotojas',
  'avatar.deleted_user_label': 'Ištrinta paskyra',
  'footer.copyright': '© Gearro',
  'footer.product': 'Produktas',
  'footer.buy_bike': 'Pirkti',
  'footer.sell_bike': 'Parduoti',
  'footer.company': 'Įmonė',
  'footer.about_us': 'Apie mus',
  'footer.support': 'Pagalba',
  'footer.faq': 'DUK',
  'footer.contact_us': 'info@gearro.com',
  'footer.business_join': 'Verslo klientų registracija',
  'footer.legal': 'Teisinė informacija',
  'footer.terms_conditions': 'Paslaugų teikimo sąlygos',
  'footer.privacy_policy': 'Privatumo politika',
  'footer.instagram_link': 'Eiti į Instagram puslapį',
  'footer.facebook_link': 'Eiti į Facebook puslapį',
  'footer.linkedin_link': 'Eiti į LinkedIn puslapį',
  'footer.content_rights': '© Gearro. Visos teisės saugomos.',
  'modal.close': 'Uždaryti langą',
  'filter_form.cancel': 'Atšaukti',
  'filter_form.clear': 'Išvalyti',
  'filter_form.submit': 'Taikyti',
  'actions.cancel': 'Atšaukti',
  'actions.clear': 'Išvalyti',
  'actions.submit': 'Taikyti',
  'actions.publish': 'Publikuoti',
  'actions.continue': 'Tęsti',
  'actions.back': 'Atgal',
  'actions.add': 'Pridėti',
  'actions.edit': 'Redaguoti',
  'actions.pay': 'Apmokėti',
  'actions.got_it': 'Supratau',
  'actions.return_home': 'Pagrindinis',
  'item_status.sold': 'Parduotas daiktas',
  'item_status.refurbished': 'Atnaujinta',
  'order_actions.accept_order': 'Priimti',
  'order_actions.reject_order': 'Atmesti',
  'order_actions.get_shipping_label': 'Gauti siuntimo etiketę',
  'order_actions.download_shipping_label': 'Atsisiųsti siuntimo etiketę',
  'order_actions.request_courier': 'Užsakyti kurjerį',
  'order_actions.track_order': 'Sekti užsakymą',
  'order_actions.mark_order_as_shipped': 'Pažymėti užsakymą kaip išsiųstą',
  'order_actions.mark_order_as_delivered': 'Pažymėti užsakymą kaip pristatytą',
  'order_actions.packing_instructions': 'Pakavimo instrukcijos',
  'order_actions.issue_notice': 'Turiu problemą',
  'order_actions.mark_as_ok': 'Viskas gerai',

  'search_page.title.all': 'Naudoti dviračiai, žiemos sporto įranga',
  'search_page.subtitle.all':
    'Pirkite kalnų, plento, gravel ir elektrinius dviračius arba atraskite naudotas slides ir snieglentes | Saugūs mokėjimai, pirkėjo apsauga ir patikimas pristatymas',

  'search_page.title.bikes': 'Dviračiai | Pirk, parduok',
  'search_page.subtitle.bikes':
    'Pirkite kalnų, plento, gravel ir elektrinius dviračius arba atraskite naudotas slides ir snieglentes | Saugūs mokėjimai, pirkėjo apsauga ir patikimas pristatymas',

  'search_page.title.parts': 'Dviračių dalys | Pirk, parduok',
  'search_page.subtitle.parts':
    'Naujos ir naudotos dviračių dalys, kalnų, plento, gravel dviračiams | Populiariausi prekių ženklai su saugiais mokėjimais ir pristatymu',

  'search_page.title.road': 'Plento dviračiai | Pirk, parduok',
  'search_page.subtitle.road':
    'Nauji ir naudoti plento dviračiai. Pasirinkite iš prekių ženklų: Cube, Trek, Canyon, Scott ir kt. | Saugūs mokėjimai, pirkėjo apsauga ir patikimas pristatymas',

  'search_page.title.mountain': 'Kalnų dviračiai | Pirk, parduok',
  'search_page.subtitle.mountain':
    'Nauji ir naudoti MTB, enduro ir downhill dviračiai. Rinkis iš prekių ženklų: Cube, Trek, Canyon, Scott ir kt. | Saugūs mokėjimai, pirkėjo apsauga ir patikimas pristatymas',

  'search_page.title.gravel': 'Gravel dviračiai | Pirk, parduok',
  'search_page.subtitle.gravel':
    'Nauji ir naudoti gravel dviračiai. Pasirinkite iš prekių ženklų: Cube, Trek, Canyon, Scott ir kt. | Saugūs mokėjimai, pirkėjo apsauga ir patikimas pristatymas',

  'search_page.title.city': 'Miesto dviračiai | Pirk, parduok',
  'search_page.subtitle.city':
    'Nauji ir naudoti miesto dviračiai. Pasirinkite iš prekių ženklų: Cube, Trek, Canyon, Scott ir kt. | Saugūs mokėjimai, pirkėjo apsauga ir patikimas pristatymas',

  'search_page.title.electric': 'Elektriniai dviračiai | Pirk, parduok',
  'search_page.subtitle.electric':
    'Nauji ir naudoti elektriniai dviračiai. Pasirinkite iš prekių ženklų: Cube, Trek, Canyon, Scott ir kt. | Saugūs mokėjimai, pirkėjo apsauga ir patikimas pristatymas',

  'search_page.title.bmx_dirt': 'BMX ir Dirt dviračiai | Pirk, parduok',
  'search_page.subtitle.bmx_dirt':
    'Nauji ir naudoti BMX ir Dirt dviračiai. Pasirinkite iš prekių ženklų: Cube, Trek, Canyon, Scott ir kt. | Saugūs mokėjimai, pirkėjo apsauga ir patikimas pristatymas',

  'search_page.title.winter': 'Slidės ir snieglentės | Pirk, parduok',
  'search_page.subtitle.winter':
    'Naujos ir naudotos slidės ir snieglentės | Populiariausi prekių ženklai su saugiais mokėjimais ir patikimu pristatymu',

  'search_page.title.skis': 'Naudotos slidės | Pirk, parduok',
  'search_page.subtitle.skis':
    'Naujos ir naudotos slidės. Pasirinkite iš prekių ženklų: Salomon, Atomic, Rossignol ir kt. | Saugūs mokėjimai, pirkėjo apsauga ir patikimas pristatymas',

  'search_page.title.snowboard': 'Snieglentės | Pirk, parduok',
  'search_page.subtitle.snowboard':
    'Naujos ir naudotos snieglentės, batai ir apkaustai. Pasirinkite iš prekių ženklų: Burton, Ride, Lib Tech ir kt. | Saugūs mokėjimai, pirkėjo apsauga ir patikimas pristatymas',

  'search_page.title.accessories': 'Žiemos sporto aksesuarai | Pirk, parduok',
  'search_page.subtitle.accessories':
    'Slidinėjimo šalmai, akiniai, batai | Saugūs mokėjimai, pirkėjo apsauga ir patikimas pristatymas',
  'search_page.price_filter.cancel': 'Atšaukti',
  'search_page.price_filter.clear': 'Išvalyti',
  'search_page.price_filter.plain_label': 'Kaina',
  'search_page.price_filter.label': 'Kainų diapazonas',
  'search_page.price_filter.submit': 'Taikyti',
  'search_page.price_filter.to': 'iki',
  'search_page.header.foundResults': '{count, number} {count, plural, one {rezultatas} other {rezultatai}}',
  'search_page.header.loadingResults': 'Kraunamos paieškos rezultatai',
  'search_page.sortBy': 'Rūšiuoti pagal',
  'search_page.filter_plain.selected': '• {count}',
  'search_page.multiple_filter.selected': '{labelText} • {count}',
  'search_page.reset_filters': 'Atstatyti filtrus',
  'search_page.no_results': 'Nepavyko rasti jokių skelbimų, atitinkančių jūsų paieškos kriterijus.',
  'search_page.empty_state.message': 'Deja, nepavyko rasti skelbimų, atitinkančių jūsų paieškos kriterijus',
  'search_page.price_filter.selected': '{minPrice} - {maxPrice}',
  'search_page.mobile_filters.label': 'Filtras',
  'search_page.mobile_filters.reset': 'Atstatyti',
  'search_page.range_filter.label.selected': '{minValue} - {maxValue}',
  'search_page.mobile.show_listings': 'Rodyti',
  'search_page.upsell_card.title': 'Pirk saugiai',
  'search_page.upsell_card.description':
    'Kiekvieną Gearro pirkinį užtikrina saugūs mokėjimai, patikimi pardavėjai ir lengvas grąžinimas.',
  'profile_page.label': 'Profilis',
  'profile_page.listing.view': 'Peržiūrėti',
  'profile_page.edit_profile': 'Redaguoti profilį',
  'profile_page.joined': 'Prisijungė {createdAt}',
  'profile_page.bikes.seller': 'Tavo skelbimai',
  'profile_page.bikes.buyer': 'Parduodami skelbimai',
  'profile_page.data_load_failed': 'Opa, įvyko klaida. Bandykite dar kartą.',
  'profile_page.bio_label': 'Apie',
  'profile_page.edit_listing': 'Redaguoti',
  'profile_page.delete_listing': 'Ištrinti',
  'profile_page.delete_modal.title': 'Ištrinti skelbimą',
  'profile_page.delete_modal.subtitle': 'Kaip pardavėte savo prekę?',
  'profile_page.delete_modal.reason.gearro': 'Pirkėją radau Gearro',
  'profile_page.delete_modal.reason.outside': 'Pirkėją radau už platformos ribų',
  'profile_page.delete_modal.reason.other': 'Kita',
  'profile_page.delete_modal.delete_action': 'Ištrinti',
  'profile_page.delete_modal.cancel_action': 'Atšaukti',
  'profile_page.no_listings.owner_label': 'Paversk savo seną dviratį į pinigus',
  'purchases_page.label': 'Pirkimai',
  'purchases_page.schema_title': 'Pirkimai | {siteTitle}',
  'purchases_page.placeholder': 'Čia bus rodomi jūsų įsigyti dviračiai.',
  'purchases_page.error_loading_purchases': 'Nepavyko įkelti pirkinių, bandykite dar kartą vėliau.',
  'purchases_page.action_button': 'Ieškoti dviračių',
  'sales_page.label': 'Pardavimai',
  'sales_page.schema_title': 'Pardavimai | {siteTitle}',
  'sales_page.placeholder': 'Čia bus rodomi jūsų parduoti dviračiai.',
  'sales_page.error_loading_sales': 'Nepavyko įkelti pardavimų, bandykite dar kartą vėliau.',
  'sales_page.action_button': 'Parduoti dabar',
  'listing_page.error_loading_listing': 'Nepavyko įkelti skelbimo, bandykite dar kartą vėliau.',
  'listing_page.error_creating_order': 'Nepavyko sukurti užsakymo, bandykite dar kartą vėliau.',
  'listing_page.sold_by': '{name}',
  'listing_page.loading': 'Kraunamas skelbimas',
  'listing_page.order_panel': 'Parduoda {name}',
  'listing_page.buy_now': 'Pirkti dabar',
  'listing_page.contact_seller': 'Parašyti pardavėjui',
  'listing_page.add_to_favorites': 'Pridėti prie mėgstamiausių',
  'listing_page.remove_from_favorites': 'Pašalinti iš mėgstamiausių',
  'listing_page.description.about_bike_label': 'Apie dviratį',
  'listing_page.details.label': 'Detalės',
  'listing_page.image_gallery.view_photos': 'Peržiūrėti nuotraukas ({count})',
  'listing_page.image_gallery.image_alt_text': 'Skelbimo nuotrauka {index}/{count}',
  'listing_page.image_gallery.image_thumb_alt_text': 'Skelbimo nuotrauka {index}/{count}',
  'listing_page.basic_information.label': 'Pagrindinė informacija',
  'listing_page.location.label': 'Vieta',
  'listing_page.seller_details.label': 'Pardavėjo informacija',
  'listing_page.offered_by.label': 'Siūlo',
  'listing_page.offered_by.phone_number': 'Telefono numeris',
  'listing_page.shopping.label': 'Pirkimas Gearro',
  'listing_page.shopping.buy_confidently.title': 'Pirkite saugiai',
  'listing_page.shopping.buy_confidently.description':
    'Tavo mokėjimas atliktas per Gearro platformą yra saugomas iki kol patvirtinsi, kad gauta prekė atitinka skelbimą.',
  'listing_page.shopping.confirm_item.title': 'Patvirtinkite prekės kokybę',
  'listing_page.shopping.confirm_item.description':
    'Po prekės pristatymo, per 48h įvertink prekės kokybę. Jei kas nors ne taip, pranešk apie tai mums ir mes pasirūpinsime pinigų grąžinimu.',
  'listing_page.shopping.release_money.title': 'Pinigų pervedimas pardavėjui',
  'listing_page.shopping.release_money.description':
    'Pinigai pardavėjui pervedami tik tada, kai pristatymas sėkmingai patvirtintas.',
  'listing_page.trust_section.secure_payments_title': '100% saugūs mokėjimai',
  'listing_page.trust_section.secure_payments_description':
    'Pinigai laikomi saugiai iki kol gaunamas Pirkėjo patvirtinimas.',
  'listing_page.trust_section.customer_support_title': 'Klientų aptarnavimas',
  'listing_page.trust_section.customer_support_description':
    'Rūpinamės jūsų patirtimi – esame čia, jei reikia pagalbos.',
  'listing_page.trust_section.free_returns_title': 'Nemokamas grąžinimas',
  'listing_page.trust_section.free_returns_description':
    'Gauta prekė neatitinka aprašymo? Grąžink ją ir atgauk pinigus.',
  'listing_page.buyer_protection_info.label': 'Gearro pirkėjo apsauga',
  'listing_page.buyer_protection_info.description': 'Tavo pirkinys yra apsaugotas su Gearro pirkėjo apsauga.',
  'listing_page.buyer_protection_info.proposition.shipping': 'Greitas ir saugus siuntimas',
  'listing_page.buyer_protection_info.proposition.secure_payments': 'Saugūs mokėjimai',
  'listing_page.buyer_protection_info.proposition.verified_sellers': 'Verifikuoti pardavėjai',
  'listing_page.buyer_protection_info.proposition.returns': 'Nemokami grąžinimai',
  'listing_page.refurbished_program.label': 'Gearro atnaujintų prekių programa',
  'listing_page.refurbished_program.description': 'Profesionaliai atnaujinta mūsų patikimo atnaujinimo partnerio.',
  'listing_page.refurbished_program.proposition.functional': 'Funkcionaliai kaip nauja',
  'listing_page.refurbished_program.proposition.warranty': '12 mėnesių garantija',
  'listing_page.refurbished_program.proposition.returns': '14 dienų grąžinimo teisė',
  'listing_page.similar_items_label': 'Panašios prekės',
  'listing_page.view_more': 'Daugiau',
  'listing_page.more_details.label': 'Daugiau detalių',
  'listing_page.pay_at_gearro':
    'Tam kad apsaugotume jūsų mokėjimą, nesidalinkite savo jautriais duomenimis už Gearro svetainės ribų.',
  'listing_order_page.schema_title': 'Pirkti {title} | {siteTitle}',
  'listing_order_page.summary': 'Santrauka',
  'listing_order_page.shipping': 'Pristatymas',
  'listing_order_page.payment': 'Mokėjimas',
  'listing_order_page.listing_summary.info_section_message':
    'Pirk be rūpesčių su Gearro pirkėjo apsaugos programa. Jei prekė neatitinka aprašymo, suteikiama visiška pinigų grąžinimo garantija.',
  'listing_order_page.listing_summary.your_purchase': 'Jūsų pirkinys',
  'listing_order_page.listing_summary.size': 'Dydis',
  'listing_order_page.listing_summary.price_details': 'Mokėjimo informacija',
  'listing_order_page.listing_summary.bike_price': 'Prekės kaina',
  'listing_order_page.listing_summary.purchase_protection': 'Pirkėjo apsaugos mokestis',
  'listing_order_page.listing_summary.shipping': 'Siuntimas',
  'listing_order_page.listing_summary.total': 'Iš viso ({currency})',
  'listing_order_page.shipping.delivery_provider': 'DPD Express pristatymas',
  'listing_order_page.shipping.delivery_estimate': 'Numatomas pristatymas',
  'listing_order_page.shipping.delivery_info': 'Pristatymo informacija',
  'listing_order_page.shipping.add_delivery_info': 'Pridėti pristatymo informaciją',
  'listing_order_page.shipping.save_address': 'Išsaugoti adresą',
  'listing_order_page.shipping.save_address_error': 'Nepavyko išsaugoti adreso, bandykite dar kartą vėliau.',
  'listing_order_page.shipping.missing_address_error': 'Jūsų pristatymo informacija reikalinga užsakymui užbaigti.',
  'listing_order_page.shipping.delivery_info_disclaimer':
    'Jūsų kontaktinė informacija gali būti bendrinama su siuntimo paslaugų teikėju užsakymo įvykdymui.',
  'listing_order_page.delivery_info.first_name': 'Vardas',
  'listing_order_page.delivery_info.first_name_placeholder': 'Vardas',
  'listing_order_page.delivery_info.first_name_required': 'Įveskite vardą',
  'listing_order_page.delivery_info.last_name': 'Pavardė',
  'listing_order_page.delivery_info.last_name_placeholder': 'Pavardė',
  'listing_order_page.delivery_info.last_name_required': 'Įveskite pavardę',
  'listing_order_page.delivery_info.country': 'Šalis',
  'listing_order_page.delivery_info.country_placeholder': 'Šalis',
  'listing_order_page.delivery_info.address_line_1': 'Adreso eilutė 1',
  'listing_order_page.delivery_info.address_line_1_placeholder': 'Adreso eilutė 1',
  'listing_order_page.delivery_info.address_line_1_required': 'Įveskite adreso eilutę 1',
  'listing_order_page.delivery_info.address_line_2': 'Adreso eilutė 2',
  'listing_order_page.delivery_info.address_line_2_placeholder': 'Adreso eilutė 2',
  'listing_order_page.delivery_info.postal_code': 'Pašto kodas',
  'listing_order_page.delivery_info.postal_code_placeholder': 'Pašto kodas',
  'listing_order_page.delivery_info.postal_code_required': 'Įveskite pašto kodą',
  'listing_order_page.delivery_info.city': 'Miestas',
  'listing_order_page.delivery_info.city_placeholder': 'Miestas',
  'listing_order_page.delivery_info.city_required': 'Įveskite miestą',
  'listing_order_page.delivery_info.phone_number': 'Telefono numeris',
  'listing_order_page.delivery_info.phone_number_placeholder': 'Telefono numeris',
  'listing_order_page.delivery_info.phone_number_required': 'Įveskite telefono numerį',
  'listing_order_page.payment.or_card': 'Arba mokėkite kortele',
  'listing_order_page.payment.mobile_pay_inactive': 'Mobilusis mokėjimas negalimas',
  'listing_order_processing_page.schema_title': 'Apdorojamas užsakymas | {siteTitle}',
  'listing_order_processing_page.title': 'Pirkimai',
  'listing_order_processing_page.processing': 'Apdorojamas užsakymas, palaukite...',
  'listing_order_processing_page.error':
    'Nepavyko apdoroti užsakymo, patikrinkite savo užsakymo būseną savo profilyje arba susisiekite su palaikymo tarnyba.',
  'listing_order_details_page.schema_title': 'Užsakymo detalės | {siteTitle}',
  'listing_order_details_page.title': 'Pirkimai',
  'listing_order_details_page.order_placed': 'Užsakymas pateiktas',
  'listing_order_details_page.whats_next': 'Kas toliau?',
  'listing_order_details_page.stage_1.title': 'Užsakymo užklausa',
  'listing_order_details_page.stage_1.description':
    'Laukiama pardavėjo patvirtinimo. Jei patvirtinimas negaunamas per 24 valandas, operacija automatiškai atšaukiama.',
  'listing_order_details_page.stage_2.title': 'Pakavimas ir siuntimas',
  'listing_order_details_page.stage_2.description':
    'Pardavėjas turi 5 dienas supakuoti ir išsiųsti jūsų užsakymą. Kai jis bus išsiųstas, gausite siuntos sekimo informaciją.',
  'listing_order_details_page.stage_3.title': 'Užsakymo patvirtinimas',
  'listing_order_details_page.stage_3.description':
    'Turite 48 valandas po prekės pristatymo, kad ją peržiūrėtumėte ir patvirtintumėte, jog ji atitinka aprašymą.',
  'listing_order_info_page.schema_title': 'Užsakymo informacija | {siteTitle}',
  'listing_order_status_page.schema_title': 'Užsakymo būsena | {siteTitle}',
  'listing_order_status_page.title': 'Užsakymo būsena',
  'listing_order_status_page.message_seller': 'Parašyti pardavėjui',
  'listing_order_status_page.message_buyer': 'Parašyti pirkėjui',
  'listing_order_status_page.order_status': 'Užsakymo būsena',
  'listing_order_status_page.delivery_address': 'Pristatymo adresas',
  'listing_order_status_page.offered_by': 'Siūlo',
  'listing_order_status_page.bought_by': 'Nupirko',
  'listing_order_status_page.payment_info': 'Mokėjimo informacija',
  'listing_order_status_page.price_details': 'Kainos detalės',
  'listing_order_status_page.bike_price': 'Skelbimo kaina',
  'listing_order_status_page.purchase_protection': 'Pirkėjo apsaugos mokestis',
  'listing_order_status_page.shipping': 'Siuntimas',
  'listing_order_status_page.total': 'Iš viso ({currency})',
  'listing_order_status_page.return_policy': 'Grąžinimo politika',
  'listing_order_status_page.return_policy_info':
    'Jei jūsų užsakymas reikšmingai neatitinka skelbimo aprašymo, susisiekite per 48 valandas. Informuokite mus apie problemą, mes peržiūrėsime jūsų užklausą. Jei ji bus pagrįsta, greitai grąžinsime pinigus.',
  'listing_order_status_page.get_help': 'Gauti pagalbą',
  'listing_order_status_page.get_help_info': 'Jei reikia pagalbos, susisiekite su mumis el. paštu info@gearro.com',
  'listing_order_status_page.reject_order_modal.title': 'Atmesti užsakymo užklausą?',
  'listing_order_status_page.reject_order_modal.description': 'Šio veiksmo negalima atšaukti.',
  'listing_order_status_page.reject_order_modal.reject_button': 'Taip, atmesti',
  'listing_order_status_page.reject_order_modal.cancel_button': 'Atšaukti',
  'listing_order_status_page.error':
    'Nepavyko įkelti užsakymo būsenos, patikrinkite URL arba bandykite dar kartą vėliau.',
  'listing_order_status_page.mark_as_ok_modal.title': 'Patvirtinti, kad viskas gerai ir užbaigti užsakymą?',
  'listing_order_status_page.mark_as_ok_modal.description':
    'Kai patvirtinsite, kad viskas gerai, jūsų mokėjimas bus pervestas pardavėjui ir jūs nebegalėsite pranešti apie problemas.',
  'listing_order_status_page.mark_as_ok_modal.confirm_button': 'Taip, viskas gerai',
  'listing_order_status_page.mark_as_ok_modal.cancel_button': 'Atšaukti',
  'listing_order_seller_address_page.schema_title': 'Gauti siuntimo etiketę | {siteTitle}',
  'listing_order_seller_address_page.title': 'Gauti siuntimo etiketę',
  'listing_order_seller_address_page.return_page': 'Užsakymo būsena',
  'listing_order_seller_address_page.shipping.delivery_provider': 'DPD Express pristatymas',
  'listing_order_seller_address_page.shipping.provider_info': 'DPD pristatymas nuo durų iki durų',
  'listing_order_seller_address_page.shipping.pick_up_info': 'Atsiėmimo informacija',
  'listing_order_seller_address_page.shipping.add_delivery_info': 'Pridėti atsiėmimo informaciją',
  'listing_order_seller_address_page.shipping.save_address_error':
    'Nepavyko išsaugoti adreso, bandykite dar kartą vėliau.',
  'listing_order_seller_address_page.shipping.get_label_btn': 'Gauti siuntimo etiketę',
  'listing_order_courier_request_page.schema_title': 'Užsakyti kurjerio atvykimą | {siteTitle}',
  'listing_order_courier_request_page.title': 'Užsakyti kurjerio atvykimą',
  'listing_order_courier_request_page.return_page': 'Užsakymo būsena',
  'listing_order_courier_request_page.form_title': 'Suplanuoti atsiėmimą',
  'listing_order_courier_request_page.form_subtitle': 'Užsakyti kurjerio atvykimą iš jūsų adreso.',
  'listing_order_courier_request_page.form_date_label': 'Atsiėmimo data',
  'listing_order_courier_request_page.form_date_placeholder': 'Pasirink atsiėmimo datą',
  'listing_order_courier_request_page.form_date_required': 'Pasirink atsiėmimo datą',
  'listing_order_courier_request_page.form_time_label': 'Atsiėmimo laikas',
  'listing_order_courier_request_page.form_time_placeholder': 'Pasirink atsiėmimo laiką',
  'listing_order_courier_request_page.form_time_required': 'Pasirink atsiėmimo laiką',
  'listing_order_courier_request_page.request_pick_up_btn': 'Užsakyti atsiėmimą',
  'orders_page.schema_title': 'Užsakymai | {siteTitle}',
  'orders_page.purchases': 'Pirkimai',
  'orders_page.sales': 'Pardavimai',
  'listing_stage_info_page.schema_title': 'Skelbimo informacija | {siteTitle}',
  'listing_stage_info_page.uploaded': 'Įkelta',
  'listing_stage_info_page.whats_next': 'Kas toliau?',
  'listing_stage_info_page.stage_1.title': 'Laukti užsakymo užklausos',
  'listing_stage_info_page.stage_1.description': 'Gavus pirkėjo užklausą, turėsite 24 valandas ją priimti.',
  'listing_stage_info_page.stage_2.title': 'Supakuoti ir išsiųsti',
  'listing_stage_info_page.stage_2.description':
    'Priėmus pirkėjo užklausą, turėsite 5 dienas užsakymui supakuoti ir išsiųsti. Jums bus pateiktos siuntimo instrukcijos.',
  'listing_stage_info_page.stage_3.title': 'Išmokėjimas',
  'listing_stage_info_page.stage_3.description':
    'Mokėjimas bus automatiškai apdorotas po 48 valandų nuo prekės sėkmingo pristatymo pirkėjui.',
  'new_listing_page.page_title': 'Ką parduosite?',
  'new_listing_page.type.bike': 'Dviratis',
  'new_listing_page.description.bike': 'Pilnas dviratis, bet kokios būklės.',
  'new_listing_page.type.part': 'Dviračio dalys',
  'new_listing_page.description.part': 'Rėmas, šakė, ratai ar kitos dalys.',
  'edit_listing_page.location.label': 'Vieta',
  'edit_listing_page.details.label': 'Detalės',
  'edit_listing_page.categories.label': 'Tipas',
  'edit_listing_page.general_details.label': 'Nuotraukos ir detalės',
  'edit_listing_page.show_listing_failed': 'Nepavyko gauti skelbimo duomenų',
  'edit_listing_page.update_failed': 'Nepavyko atnaujinti skelbimo. Bandykite dar kartą.',
  'edit_listing_page.upload_failed': 'Nepavyko publikuoti skelbimo',
  'edit_listing_page.max_length': 'Turi būti {maxLength} simbolių arba mažiau',
  'edit_listing_page.location.tab_title': 'Parduokite savo naują arba naudotą dviratį',
  'edit_listing_page.location.address': 'Kur yra jūsų dviratis?',
  'edit_listing_page.location.address_hint': 'Tai nebus matoma kitiems nariams.',
  'edit_listing_page.location.address_not_recognized': 'Nepavyko atpažinti šios vietos. Bandykite kitą vietą.',
  'edit_listing_page.location.address_placeholder': 'Įveskite adresą',
  'edit_listing_page.location.address_required': 'Turite pateikti vietą',
  'edit_listing_page.location.shipping_label': 'Ar išsiųsite dviratį?',
  'edit_listing_page.location.shipping_required': 'Pasirink siuntimo metodą',
  'edit_listing_page.categories.tab_title': 'Kaip geriausiai apibūdintumėte savo dviratį?',
  'edit_listing_page.categories.type_select': 'Pasirink dviračio tipą',
  'edit_listing_page.categories.sub_category_required': 'Pasirink dviračio subkategoriją',
  'edit_listing_page.categories.category_required': 'Pasirink dviračio kategoriją',
  'edit_listing_page.details.tab_title': 'Bendra informacija apie jūsų dviratį',
  'edit_listing_page.details.frame_details': 'Rėmo detalės',
  'edit_listing_page.details.brand': 'Prekės ženklas',
  'edit_listing_page.details.brand_placeholder': 'Pasirink dviračio prekės ženklą',
  'edit_listing_page.details.brand_required': 'Pasirink prekės ženklą',
  'edit_listing_page.details.model': 'Modelis',
  'edit_listing_page.details.model_placeholder': 'Įveskite dviračio rėmo modelį',
  'edit_listing_page.details.model_required': 'Įveskite dviračio rėmo modelį',
  'edit_listing_page.details.year': 'Metai',
  'edit_listing_page.details.year_placeholder': 'Pasirink metus',
  'edit_listing_page.details.year_required': 'Pasirink metus',
  'edit_listing_page.details.frame_size': 'Rėmo dydis',
  'edit_listing_page.details.frame_size_placeholder': 'Pasirink rėmo dydį',
  'edit_listing_page.details.frame_size_required': 'Pasirink rėmo dydį',
  'edit_listing_page.details.frame_material': 'Medžiaga',
  'edit_listing_page.details.frame_material_required': 'Pasirink medžiagą',
  'edit_listing_page.details.frame_color': 'Spalva',
  'edit_listing_page.details.frame_color_required': 'Pasirink spalvą',
  'edit_listing_page.details.wheel_size': 'Ratų dydis',
  'edit_listing_page.details.wheel_size_info':
    'Ratų dydis yra dviračio rato skersmuo, paprastai nurodytas ant padangos.',
  'edit_listing_page.details.wheel_size_placeholder': 'Pasirink ratų dydį',
  'edit_listing_page.details.wheel_size_required': 'Pasirink ratų dydį',
  'edit_listing_page.details.component_details': 'Komponentų detalės',
  'edit_listing_page.details.component_details_placeholder':
    'Kokie komponentai - pavarų grupė, ratai, šakė, amortizatoriai, sėdynės stulpelis ir kt. - yra ant dviračio? Pvz., Shimano Dura-Ace pavarų grupė, Bontrager Aeolus Pro 3v TLR ratai...',
  'edit_listing_page.details.component_details_required': 'Įveskite komponentų detales',
  'edit_listing_page.details.frame_size_table_title': 'Tinkamas rėmo dydis',
  'edit_listing_page.details.frame_size_table_info':
    'Jūsų dviračio rėmo dydis paprastai nurodytas ant rėmo sėdynės vamzdžio. Jei jo nerandate, žiūrėkite toliau pateiktą lentelę.',
  'edit_listing_page.details.frame_size_table_header_1': 'Rėmo dydis',
  'edit_listing_page.details.frame_size_table_header_2': 'Rėmo dydis cm',
  'edit_listing_page.details.wheel_size.hint':
    'Ratų dydis yra dviračio rato skersmuo, paprastai nurodytas ant padangos.',
  'edit_listing_page.general_details.tab_title': 'Pridėkite keletą dviračio nuotraukų',
  'edit_listing_page.general_details.image_upload_failed': 'Nepavyko įkelti nuotraukos',
  'edit_listing_page.general_details.image_upload_over_limit': 'Maksimalus leistinas failo dydis yra 20 MB',
  'edit_listing_page.general_details.image_required': 'Įkelkite bent 3 nuotraukas',
  'edit_listing_page.general_details.parts.image_required': 'Įkelkite bent 3 nuotraukas',
  'edit_listing_page.general_details.image_upload.info': 'Pasirink bent 3 nuotraukas',
  'edit_listing_page.general_details.image_upload.from_device': 'Įkelti iš įrenginio',
  'edit_listing_page.general_details.image_upload.add_more': 'Pridėti daugiau',
  'edit_listing_page.general_details.image_upload.main_image': 'Pagrindinė nuotrauka',
  'edit_listing_page.general_details.title': 'Pavadinimas',
  'edit_listing_page.general_details.title_placeholder': 'pvz., Trek Slash 9.7',
  'edit_listing_page.general_details.title_required': 'Įveskite pavadinimą',
  'edit_listing_page.general_details.condition': 'Būklė',
  'edit_listing_page.general_details.condition_required': 'Pasirink dviračio būklę',
  'edit_listing_page.general_details.receipt': 'Ar dviratis turi originalų kvitą?',
  'edit_listing_page.general_details.receipt_required': 'Pateikite kvito prieinamumą',
  'edit_listing_page.general_details.refurbished': 'Atnaujintas daiktas?',
  'edit_listing_page.general_details.refurbished_info':
    'Atnaujintas daiktas turi 12 mėnesių garantiją, kurią suteikia pardavėjas, ir 14 dienų grąžinimo teisę.',
  'edit_listing_page.general_details.refurbished_required': 'Praneškite, ar prekė yra atnaujinta',
  'edit_listing_page.general_details.price': 'Kaina',
  'edit_listing_page.general_details.placeholder': '€0.00',
  'edit_listing_page.general_details.price_required': 'Nurodykite kainą',
  'edit_listing_page.general_details.price_too_low': 'Kaina turėtų būti bent {minPrice}.',
  'edit_listing_page.general_details.price_too_high': 'Kaina neturėtų viršyti {maxPrice}.',
  'edit_listing_page.general_details.saved_image_alt': 'Išsaugota skelbimo nuotrauka',
  'edit_listing_page.payment_info.tab_title': 'Mokėjimo informacija',
  'edit_listing_page.payment_info.label': 'Mokėjimo informacija',
  'edit_listing_page.payment_info.info_section_title':
    'Užbaikite savo profilį, kad galėtumėte gauti išmokas už dviratį',
  'edit_listing_page.payment_info.info_section_message':
    'Norėdami apdoroti dviračio išmoką, įveskite savo duomenis žemiau. Tai reikės padaryti tik vieną kartą.',
  'edit_listing_page.payment_info.first_name': 'Vardas',
  'edit_listing_page.payment_info.first_name_placeholder': 'Vardas',
  'edit_listing_page.payment_info.first_name_required': 'Įveskite vardą',
  'edit_listing_page.payment_info.last_name': 'Pavardė',
  'edit_listing_page.payment_info.last_name_placeholder': 'Pavardė',
  'edit_listing_page.payment_info.last_name_required': 'Įveskite pavardę',
  'edit_listing_page.payment_info.date_of_birth': 'Gimimo data',
  'edit_listing_page.payment_info.date_of_birth_placeholder': 'Gimimo data',
  'edit_listing_page.payment_info.date_of_birth_required': 'Įveskite galiojančią gimimo datą',
  'edit_listing_page.payment_info.address_line_1': 'Adreso eilutė 1',
  'edit_listing_page.payment_info.address_line_1_placeholder': 'Adreso eilutė 1',
  'edit_listing_page.payment_info.address_line_1_required': 'Įveskite adreso eilutę 1',
  'edit_listing_page.payment_info.address_line_2': 'Adreso eilutė 2',
  'edit_listing_page.payment_info.address_line_2_placeholder': 'Adreso eilutė 2',
  'edit_listing_page.payment_info.postal_code': 'Pašto kodas',
  'edit_listing_page.payment_info.postal_code_placeholder': 'Pašto kodas',
  'edit_listing_page.payment_info.postal_code_required': 'Įveskite pašto kodą',
  'edit_listing_page.payment_info.city': 'Miestas',
  'edit_listing_page.payment_info.city_placeholder': 'Miestas',
  'edit_listing_page.payment_info.city_required': 'Įveskite miestą',
  'FieldBirthdayInput.birthdayDatePlaceholder': 'DD',
  'FieldBirthdayInput.birthdayMonthPlaceholder': 'MM',
  'FieldBirthdayInput.birthdayYearPlaceholder': 'YYYY',
  'edit_listing_page.payment_info.terms_and_conditions_required': 'Prašome sutikti su taisyklėmis ir sąlygomis',
  'edit_listing_page.payment_info.payment_provider_error': 'Įvyko klaida su mokėjimo tiekėju. Bandykite dar kartą.',
  'edit_listing_page.payment_info.generic_error': 'Įvyko klaida. Bandykite dar kartą.',
  'parts_listing_page.details.label': 'Detalės',
  'parts_listing_page.categories.label': 'Tipas',
  'parts_listing_page.general_details.label': 'Nuotraukos ir detalės',
  'parts_listing_page.specifications.label': 'Specifikacijos',
  'parts_listing_page.payment_information.label': 'Mokėjimo informacija',
  'parts_listing_page.categories.tab_title': 'Ką parduosite?',
  'parts_listing_page.details.tab_title': 'Bendra informacija apie jūsų įrangą',
  'parts_listing_page.specification.tab_title': 'Pasidalinkite įrangos specifikacijomis',
  'parts_listing_page.specification.panel_description': 'Padidinkite pardavimo galimybę pateikdami papildomas detales.',
  'parts_listing_page.details.sub_category': 'Subkategorija',
  'parts_listing_page.details.sub_category_required': 'Pasirink subkategoriją',
  'parts_listing_page.details.sub_category_placeholder': 'Pasirink subkategoriją',
  'parts_listing_page.details.brand': 'Prekės ženklas',
  'parts_listing_page.details.brand_placeholder': 'Pasirink komponento prekės ženklą',
  'parts_listing_page.details.brand_required': 'Pasirink prekės ženklą',
  'parts_listing_page.details.model': 'Modelis',
  'parts_listing_page.details.model_placeholder': 'Įveskite komponento modelį',
  'parts_listing_page.details.model_required': 'Įveskite komponento modelį',
  'parts_listing_page.details.year_placeholder': 'Pasirink metus',
  'parts_listing_page.specification.more_details': 'Daugiau detalių',
  'parts_listing_page.specifications.frame_size': 'Dydis',
  'parts_listing_page.specifications.frame_size_placeholder': 'Pasirink dydį',
  'parts_listing_page.specifications.frame_size_required': 'Pasirink dydį',
  'parts_listing_page.specifications.frame_material': 'Medžiaga',
  'parts_listing_page.specifications.frame_material_placeholder': 'Pasirink medžiagą',
  'parts_listing_page.specifications.frame_material_required': 'Pasirink medžiagą',
  'parts_listing_page.specifications.wheel_size': 'Ratų dydis',
  'parts_listing_page.specifications.wheel_size_placeholder': 'Pasirink ratų dydį',
  'parts_listing_page.specifications.wheel_size_required': 'Pasirink ratų dydį',
  'parts_listing_page.specifications.front_travel': 'Priekinės šakės eiga',
  'parts_listing_page.specifications.front_travel_placeholder': 'Įveskite priekinės šakės eigą',
  'parts_listing_page.specifications.front_travel_required': 'Įveskite priekinės šakės eigą',
  'parts_listing_page.specifications.rear_travel': 'Galinės šakės eiga',
  'parts_listing_page.specifications.rear_travel_placeholder': 'Įveskite galinės šakės eigą',
  'parts_listing_page.specifications.rear_travel_required': 'Įveskite galinės šakės eigą',
  'parts_listing_page.specifications.discipline': 'Disciplina',
  'parts_listing_page.specifications.discipline_placeholder': 'Pasirink discipliną',
  'parts_listing_page.specifications.hub_standard': 'Stebulės standartas',
  'parts_listing_page.specifications.hub_standard_placeholder': 'Pasirink stebulės standartą',
  'parts_listing_page.specifications.suspension_type': 'Tipas',
  'parts_listing_page.specifications.suspension_mounting': 'Tvirtinimas',
  'parts_listing_page.specifications.suspension_length': 'Ilgis',
  'parts_listing_page.specifications.suspension_length_placeholder': 'Įveskite ilgį',
  'parts_listing_page.specifications.suspension_stroke': 'Eiga',
  'parts_listing_page.specifications.suspension_stroke_placeholder': 'Įveskite eigos ilgį',
  'parts_listing_page.specifications.axle_dimension': 'Ašies matmuo',
  'parts_listing_page.specifications.axle_dimension_placeholder': 'Pasirink ašies matmenį',
  'parts_listing_page.specifications.crank_arm_length': 'Švaistiklio ilgis',
  'parts_listing_page.specifications.crank_arm_length_placeholder': 'Įveskite švaistiklio ilgį',
  'parts_listing_page.specifications.gears': 'Pavaros',
  'parts_listing_page.specifications.gears_placeholder': 'Pasirink pavaras',
  'parts_listing_page.specifications.placement': 'Tipas',
  'parts_listing_page.specifications.activation': 'Aktyvavimas',
  'parts_listing_page.specifications.front_gears': 'Priekinės pavaros',
  'parts_listing_page.specifications.front_gears_placeholder': 'Pasirink priekines pavaras',
  'parts_listing_page.specifications.rear_gears': 'Galinės pavaros',
  'parts_listing_page.specifications.rear_gears_placeholder': 'Pasirink galines pavaras',
  'parts_listing_page.specifications.chainring_mounting': 'Tvirtinimas',
  'parts_listing_page.specifications.chainring_mounting_placeholder': 'Pasirink tvirtinimą',
  'parts_listing_page.specifications.chainring_teeth': 'Dantys',
  'parts_listing_page.specifications.chainring_teeth_placeholder': 'Įveskite dantų skaičių',
  'parts_listing_page.specifications.bottom_bracket_mounting': 'Tvirtinimas',
  'parts_listing_page.specifications.bottom_bracket_mounting_placeholder': 'Pasirink tvirtinimą',
  'parts_listing_page.specifications.bottom_bracket_width': 'Plotis',
  'parts_listing_page.specifications.bottom_bracket_width_placeholder': 'Įveskite plotį',
  'parts_listing_page.specifications.pedals': 'Tipas',
  'parts_listing_page.specifications.pedals_placeholder': 'Pasirink tipą',
  'parts_listing_page.specifications.brake_type': 'Tipas',
  'parts_listing_page.specifications.brakes_activation': 'Aktyvavimas',
  'parts_listing_page.specifications.rotor_mounting': 'Tvirtinimas',
  'parts_listing_page.specifications.rotor_placement': 'Tipas',
  'parts_listing_page.specifications.front_disk_diameter': 'Priekinio disko skersmuo',
  'parts_listing_page.specifications.front_disk_diameter_placeholder': 'Įveskite priekinio disko skersmenį',
  'parts_listing_page.specifications.rear_disk_diameter': 'Galinio disko skersmuo',
  'parts_listing_page.specifications.rear_disk_diameter_placeholder': 'Įveskite galinio disko skersmenį',
  'parts_listing_page.specifications.wheels_placement': 'Tipas',
  'parts_listing_page.specifications.front_wheel_size': 'Priekinio rato dydis',
  'parts_listing_page.specifications.front_wheel_size_placeholder': 'Pasirink priekinio rato dydį',
  'parts_listing_page.specifications.rear_wheel_size': 'Galinio rato dydis',
  'parts_listing_page.specifications.rear_wheel_size_placeholder': 'Pasirink galinio rato dydį',
  'parts_listing_page.specifications.hubs_placement': 'Tipas',
  'parts_listing_page.specifications.front_hub_standard': 'Priekinis matmuo',
  'parts_listing_page.specifications.front_hub_standard_placeholder': 'Pasirink priekinės stebulės matmenį',
  'parts_listing_page.specifications.rear_hub_standard': 'Galinio matmuo',
  'parts_listing_page.specifications.rear_hub_standard_placeholder': 'Pasirink galinės stebulės matmenį',
  'parts_listing_page.specifications.inner_rim_width': 'Vidinis ratlankio plotis',
  'parts_listing_page.specifications.inner_rim_width_placeholder': 'Įveskite vidinį ratlankio plotį',
  'parts_listing_page.specifications.handlebar_type': 'Tipas',
  'parts_listing_page.specifications.handlebar_type_placeholder': 'Pasirink vairo tipą',
  'parts_listing_page.specifications.handlebar_width': 'Plotis',
  'parts_listing_page.specifications.handlebar_width_placeholder': 'Įveskite vairo plotį',
  'parts_listing_page.specifications.stem_length': 'Ilgis',
  'parts_listing_page.specifications.stem_length_placeholder': 'Įveskite iškyšos ilgį',
  'parts_listing_page.specifications.grips_type': 'Tipas',
  'parts_listing_page.specifications.grips_type_placeholder': 'Pasirink tipą',
  'parts_listing_page.specifications.steerer_type': 'Vairo kolonėlės tipas',
  'parts_listing_page.specifications.steerer_type_placeholder': 'Pasirink vairo kolonėlės tipą',
  'parts_listing_page.specifications.dropper_travel': 'Eiga',
  'parts_listing_page.specifications.dropper_travel_placeholder': 'Įveskite eigą',
  'parts_listing_page.specifications.dropper_diameter': 'Skersmuo',
  'parts_listing_page.specifications.dropper_diameter_placeholder': 'Pasirink skersmenį',
  'parts_listing_page.specifications.dropper_activation': 'Aktyvavimas',
  'parts_listing_page.specifications.dropper_activation_placeholder': 'Pasirink aktyvavimą',
  'parts_listing_page.specifications.seat_length': 'Ilgis',
  'parts_listing_page.specifications.seat_length_placeholder': 'Įveskite sėdynės ilgį',
  'parts_listing_page.specifications.brake_type_placeholder': 'Pasirink tipą',
  'parts_listing_page.general_details.tab_title': 'Pridėkite keletą nuotraukų apie savo įrangą',
  'parts_listing_page.general_details.description.label': 'Aprašymas',
  'parts_listing_page.general_details.description.placeholder':
    'Kita informacija apie parduodamą daiktą, įskaitant trūkumus, pakuotę, kvitą ir kitas svarbias specifikacijas.',
  'parts_listing_page.general_details.description.required': 'Pateikite įrangos aprašymą',
  'parts_listing_page.location.label': 'Vieta',
  'parts_listing_page.general_details.shipping.label': 'Siuntimas',
  'parts_listing_page.general_details.shipping.required': 'Pasirink siuntimo parinktį',
  'parts_listing_page.general_details.image_upload.info': 'Pasirink bent 3 nuotraukas',
  'your_listings_page.edit_listing': 'Redaguoti skelbimą',
  'your_listings_page.delete_listing': 'Ištrinti skelbimą',
  'your_listings_page.delete_draft': 'Ištrinti juodraštį',
  'your_listings_page.title': 'Dviračiai pardavimui',
  'your_listings_page.schema_title': 'Jūsų skelbimai',
  'your_listings_page.delete_modal.title': 'Ar tikrai norite ištrinti dviratį?',
  'your_listings_page.delete_modal.delete_action': 'Ištrinti',
  'your_listings_page.delete_modal.cancel_action': 'Atšaukti',
  'inbox_page.label': 'Gautieji',
  'inbox_page.empty_state.message': 'Jūs neturite jokių žinučių',
  'inbox_page.message_input.placeholder': 'Siųsti žinutę',
  'about_page.title': 'Apie mus',
  'about_page.mission_statement':
    'Mes siekiame padaryti dviračių sportą prieinamą ir įperkama visiems, nepaisant amžiaus, lyties, fizinių gebėjimų ar socialinės padėties. Esame aistringi tvariam transportui ir sveikam gyvenimo būdui. Todėl mūsų platforma sujungia pirkėjus ir pardavėjus iš viso pasaulio, siūlydama platų aukštos kokybės naudotų dviračių pasirinkimą už prieinamą kainą.',
  'about_page.sustainability_title': 'Tvarumas',
  'about_page.sustainability_statement':
    'Važiavimas dviračiu ir naudoto dviračio pirkimas yra tvarūs pasirinkimai, naudingi tiek žmonėms, tiek aplinkai. Dviračių sportas mažina anglies dvideginio emisijas ir oro taršą, o naudoto dviračio pirkimas prailgina jo tarnavimo laiką ir sumažina atliekų kiekį. Be to, dviračių sportas skatina sveikesnį gyvenimo būdą, mažindamas sveikatos priežiūros sistemos naštą. Šie tvarūs sprendimai prisideda prie sveikesnės ir tvaresnės ateities visiems.',
  'about_page.our_work_title': 'Mūsų darbas',
  'about_page.our_work_statement':
    'Mes esame čia nuo pat pirmųjų downhill lenktynių Lietuvoje 2008 metais. Mūsų aistra dviračių sportui ir bendruomeniškumo jausmas vedė mus į priekį. Sukūrėme filmus, organizavome renginius, dalinomės patirtimi ir siekėme skleisti meilę dviračių sportui kuo plačiau. Tikime, kad dviračių sporto džiaugsmas turėtų būti prieinamas visiems, ir esame tam pasiryžę.',
  'about_page.our_team_title': 'Mūsų komanda',
  'about_page.our_team_statement':
    'Mes esame aistringa komanda, įsikūrusi Vilniuje, Lietuvoje, ir siekiame spręsti dviračių sporto iššūkius. Stengiamės tobulinti dviračių patirtį ir skatinti tvaraus transporto sprendimus. Dirbkime kartu kurdami geresnę ateitį visiems.',
  'profile_settings.title': 'Profilio nustatymai',
  'profile_settings.first_name.label': 'Vardas',
  'profile_settings.first_name.placeholder': 'Vardas',
  'profile_settings.first_name.required': 'Prašome įvesti vardą',
  'profile_settings.last_name.label': 'Pavardė',
  'profile_settings.last_name.placeholder': 'Pavardė',
  'profile_settings.last_name.required': 'Prašome įvesti pavardę',
  'profile_settings.user_name.label': 'Vartotojo vardas',
  'profile_settings.user_name.placeholder': 'Vartotojo vardas',
  'profile_settings.user_name.required': 'Prašome įvesti vartotojo vardą',
  'profile_settings.bio.label': 'Aprašymas',
  'profile_settings.bio.placeholder': 'Paprašome trumpai papasakoti apie save...',
  'profile_settings.save': 'Išsaugoti pakeitimus',
  'profile_settings.profile_picture.file_too_large': 'Įkelkite mažesnę nuotrauką',
  'profile_settings.profile_picture.upload_failed': 'Profilio nuotraukos įkėlimas nepavyko',
  'profile_settings.profile_picture.edit': 'Redaguoti nuotrauką',
  'profile_settings.profile_update_failed': 'Profilio atnaujinimas nepavyko',
  'account_settings.label': 'Nustatymai',
  'account_settings.page_title': 'Paskyros nustatymai',
  'account_settings.email.heading': 'Kontaktiniai duomenys',
  'account_settings.email.verified': 'Jūsų el. paštas patvirtintas.',
  'account_settings.errors.generic': 'Įvyko klaida, bandykite dar kartą.',
  'account_settings.email.label': 'El. pašto adresas',
  'account_settings.email.required': 'Įveskite el. pašto adresą',
  'account_settings.email.invalid': 'Įvestas el. pašto adresas yra neteisingas',
  'account_settings.email.taken': 'El. pašto adresas jau naudojamas',
  'account_settings.confirm_password.label': 'Slaptažodis',
  'account_settings.confirm_password.placeholder': 'Įveskite naują slaptažodį',
  'account_settings.confirm_password.required': 'Įveskite slaptažodį',
  'account_settings.confirm_password.too_short': 'Slaptažodis turi būti bent {minLength} simbolių',
  'account_settings.confirm_email_change_label':
    'Norėdami patvirtinti el. pašto adreso keitimą, įveskite dabartinį slaptažodį',
  'account_settings.confirm_password.failed': 'Neteisingas slaptažodis',
  'account_settings.password.heading': 'Sauga',
  'account_settings.save_changes': 'Išsaugoti pakeitimus',
  'password_recovery_page.forgot_password.title': 'Pamiršote slaptažodį?',
  'password_recovery_page.forgot_password.message':
    'Įveskite el. pašto adresą, kurį naudojote registruojantis. Mes atsiųsime jums el. laišką su slaptažodžio nustatymo nuoroda.',
  'password_recovery_page.forgot_password.email.label': 'El. paštas',
  'password_recovery_page.forgot_password.email.placeholder': 'El. paštas',
  'password_recovery_page.forgot_password.email.required': 'Įveskite el. paštą',
  'password_recovery_page.forgot_password.email.not_found': 'El. paštas nerastas',
  'password_recovery_page.forgot_password.email.invalid': 'Neteisingas el. paštas',
  'password_recovery_page.forgot_password.submit': 'Siųsti',
  'PasswordRecoveryForm.emailInvalid': 'Reikalingas galiojantis el. pašto adresas',
  'PasswordRecoveryForm.emailLabel': 'El. paštas',
  'PasswordRecoveryForm.emailNotFound':
    'Hmm. Neradome paskyros su šiuo el. pašto adresu. Patikrinkite adresą ir bandykite dar kartą.',
  'PasswordRecoveryForm.emailPlaceholder': 'john.doe@example.com',
  'PasswordRecoveryForm.emailRequired': 'Šis laukas yra privalomas',
  'PasswordRecoveryForm.loginLinkInfo': 'Staiga prisiminėte savo slaptažodį? {loginLink}',
  'PasswordRecoveryForm.loginLinkText': 'Prisijungti.',
  'PasswordRecoveryForm.sendInstructions': 'Siųsti instrukcijas',
  'PasswordRecoveryPage.actionFailedMessage': 'Įvyko klaida. Atnaujinkite puslapį ir bandykite dar kartą.',
  'PasswordRecoveryPage.actionFailedTitle': 'Opa!',
  'PasswordRecoveryPage.emailSubmittedMessage':
    'Instrukcijos slaptažodžio atkūrimui buvo išsiųstos į {submittedEmailText}.',
  'PasswordRecoveryPage.emailSubmittedTitle': 'Patikrinkite savo gautus laiškus',
  'PasswordRecoveryPage.fixEmailInfo': 'Opa, klaida el. pašte? {fixEmailLink}',
  'PasswordRecoveryPage.fixEmailLinkText': 'Taisyti.',
  'PasswordRecoveryPage.forgotPasswordMessage':
    'Nieko tokio! Įveskite el. pašto adresą, kurį naudojote registruodamiesi, ir mes atsiųsime instrukcijas, kaip nustatyti naują slaptažodį.',
  'PasswordRecoveryPage.forgotPasswordTitle': 'Pamiršote slaptažodį?',
  'PasswordRecoveryPage.resendEmailInfo': 'Negavote el. laiško? {resendEmailLink}',
  'PasswordRecoveryPage.resendEmailLinkText': 'Siųsti dar kartą.',
  'PasswordRecoveryPage.resendingEmailInfo': 'Persiunčiamos instrukcijos…',
  'PasswordRecoveryPage.title': 'Prašyti naujo slaptažodžio',
  'favorite_listings_page.label': 'Mėgstamiausi skelbimai',
  'favorite_listings_page.search_button.bike': 'Ieškoti dviračių',
  'favorite_listings_page.search_button.parts': 'Ieškoti dalių',
  'favorite_listings_page.search_button.winter': 'Ieškoti žiemos įrangos',
  'favorite_listings_page.empty_state.bike.title': 'Jūsų išsaugoti dviračiai',
  'favorite_listings_page.empty_state.bike.description': 'Kai išsaugosite dviratį, jis pasirodys čia',
  'favorite_listings_page.empty_state.parts.title': 'Jūsų išsaugotos dviračio dalys',
  'favorite_listings_page.empty_state.parts.description': 'Kai išsaugosite dviračio dalį, ji pasirodys čia',
  'favorite_listings_page.empty_state.winter.title': 'Jūsų išsaugota žiemos įranga',
  'favorite_listings_page.empty_state.winter.description': 'Kai išsaugosite žiemos įrangą, ji pasirodys čia',
  'favorite_listings_page.schema_title': 'Mėgstamiausi skelbimai',
  'favorite_listings_page.tabs.bikes': 'Dviračiai',
  'favorite_listings_page.tabs.parts': 'Dalys',
  'favorite_listings_page.tabs.winter': 'Žiemos įranga',
  'sign_up.confirm_signup_with_idp_title': 'Registruotis su {idp}',
  'sign_up.confirm_signup_info_text': 'Patikrinkite, ar jūsų informacija teisinga.',
  'cookies.title': 'Mes naudojame slapukus',
  'cookies.consent_message':
    'Gearro naudoja slapukus naršymo patirčiai gerinti, turiniui ir skelbimams suasmeninti bei svetainės srautui analizuoti. Paspausdami „Sutinku“, jūs sutinkate su slapukų naudojimu. Jei nenorite leisti slapukų, galite juos išjungti naršyklės nustatymuose.',
  'cookies.accept': 'Sutinku',
  'pagination.previous': 'Ankstesnis puslapis',
  'pagination.next': 'Kitas puslapis',
  'pagination.to_page': 'Eiti į {page} puslapį',
  'validators.min_max_value': 'Reikšmė turi būti tarp {minValue} ir {maxValue}',
  'validators.min_max_value.invalid_type.numeric': 'Reikšmė turi būti skaitinė',
  'about_page.schema_title': 'Apie mus | {siteTitle}',
  'about_page.schema_description': 'Apie Gearro',
  'listing_page.schema_title': '{title} | {siteTitle}',
  'profile_page.schema_title': '{name} | {siteTitle}',
  'search_page.schema_for_search': 'užklausa',
  'page.schema_title': 'Parduok ir pirk dviračius | {siteTitle}',
  'page.schema_description': 'Didžiausia internetinė dviračių bendruomenė.',
  'edit_listing_page.schema_title': 'Sukurti skelbimą',
  'log_in.page_schema': 'Prisijungti | Gearro',
  'sign_up.page_schema': 'Registruotis | Gearro',
  'password_recovery_page.title': 'Prašyti naujo slaptažodžio',
  'inbox_page.schema_title': 'Gautieji',
  'new_listing_page.schema_title': 'Naujas skelbimas',
  'search_page.schema_title': 'Pirk naudotus dviračius | Gearro',
  'search_page.schema_description': 'Pirk ir parduok naudotus dviračius Gearro.',
  'search_page.schema_title.bike': 'Atrask savo kitą dviratį: kokybiški naudoti dviračiai | Gearro',
  'search_page.schema_description.bike':
    'Išsirink platų naudotų dviračių asortimentą Gearro. Rask savo idealų plentinį, kalnų ar gravel dviratį su saugiais sandoriais ir pirkėjo apsauga. Pradėk savo dviračių nuotykį jau šiandien.',
  'search_page.schema_title.bike.road': 'Aukštos klasės plentiniai dviračiai | Gearro',
  'search_page.schema_description.bike.road':
    'Atrask aukščiausios kokybės plentinius dviračius greičiui ir efektyvumui. Peržiūrėk mūsų lengvus, aerodinaminius modelius Gearro.',
  'search_page.schema_title.bike.mountain': 'Kalnų dviračiai | MTB | Gearro',
  'search_page.schema_description.bike.mountain':
    'Įveik sudėtingiausius takus su mūsų tvirtais kalnų dviračiais. Sukurti patvarumui ir kontrolei, Gearro kolekcija atitinka visus bekelės važiavimo poreikius.',
  'search_page.schema_title.bike.gravel': 'Gravel dviračiai | Gearro',
  'search_page.schema_description.bike.gravel':
    'Keliauk už asfaltuotų kelių ribų su mūsų universaliais gravel dviračiais, skirtais patogiam ir patikimam važiavimui mišrioje vietovėje. Atrask daugiau su Gearro.',
  'search_page.schema_title.bike.city': 'Pirk arba parduok naudotus miesto dviračius',
  'search_page.schema_description.bike.city':
    'Atrask mūsų aukštos kokybės miesto dviračių pasirinkimą, puikiai tinkantį urbanistiniams dviratininkams. Rask komforto ir stiliaus derinį kasdieniams maršrutams su Gearro.',
  'search_page.schema_title.bike.electric': 'Elektriniai dviračiai | E-dviračiai | Gearro',
  'search_page.schema_description.bike.electric':
    'Patobulink savo važiavimą su elektriniais dviračiais, siūlančiais galios asistenciją lengvesniam ir greitesniam važiavimui. Rask sau tinkamiausią e-dviratį Gearro.',
  'search_page.schema_title.bike.bmx_dirt': 'BMX & Dirt dviračiai | Gearro',
  'search_page.schema_description.bike.bmx_dirt':
    'Leiskis į veiksmą su mūsų BMX ir Dirt dviračiais, sukurtais freestyle ir bekelės iššūkiams Gearro.',
  'search_page.schema_title.bike.folding': 'Kompaktiški sulankstomi dviračiai | Nešiojami dviračiai | Gearro',
  'search_page.schema_description.bike.folding':
    'Maksimalus patogumas su mūsų sulankstomais dviračiais, derinančiais nešiojamumą ir našumą. Puikus pasirinkimas kasdieniam susisiekimui ir laikymui mažose erdvėse.',
  'search_page.schema_title.bike.other': 'Unikalūs ir specializuoti dviračiai | Rask savo tobulą dviratį | Gearro',
  'search_page.schema_description.bike.other':
    'Ieškote kažko išskirtinio? Atraskite unikalius ir specializuotus dviračius pagal savo poreikius. Peržiūrėkite mūsų įvairią kolekciją Gearro.',
  'search_page.schema_title.parts': 'Dviračių dalys ir komponentai | Gearro',
  'search_page.schema_description.parts':
    'Pirk aukštos kokybės dviračių dalis ir komponentus. Raskite rėmus, amortizatorius, pavarų sistemas, stabdžius ir daugiau savo dviračiui Gearro.',
  'search_page.schema_title.parts.frame': 'Dviračių rėmai pardavimui | Gearro',
  'search_page.schema_description.parts.frame':
    'Atraskite patvarius ir lengvus dviračių rėmus. Rinkitės tobulą bazę savo individualiam dviračiui Gearro.',
  'search_page.schema_title.parts.suspension': 'Dviračių amortizavimo sistemos | Gearro',
  'search_page.schema_description.parts.suspension':
    'Pagerinkite savo važiavimą su aukščiausios kokybės dviračių amortizavimo sistemomis. Raskite šakes ir amortizatorius sklandžiam važiavimui Gearro.',
  'search_page.schema_title.parts.drivetrain': 'Dviračių pavarų sistemos | Gearro',
  'search_page.schema_description.parts.drivetrain':
    'Atnaujinkite savo dviratį patikimomis pavarų sistemomis. Pirkite grandines, perjungiklius ir švaistiklius efektyviam galios perdavimui Gearro.',
  'search_page.schema_title.parts.brakes': 'Dviračių stabdžiai | Gearro',
  'search_page.schema_description.parts.brakes':
    'Užtikrinkite savo saugumą su aukštos kokybės dviračių stabdžiais. Nuo diskininių iki ratlankinių stabdžių – viskas, ko reikia Gearro.',
  'search_page.schema_title.parts.wheels': 'Dviračių ratai ir ratlankiai | Gearro',
  'search_page.schema_description.parts.wheels':
    'Riedėkite sklandžiai su mūsų dviračių ratų ir ratlankių pasirinkimu. Atraskite patvarius variantus visiems reljefams Gearro.',
  'search_page.schema_title.parts.tyres_tubes': 'Dviračių padangos bet kokiai dangai | Gearro',
  'search_page.schema_description.parts.tyres_tubes':
    'Raskite tobulas dviračių padangas bet kokiam reljefui Gearro. Pasirink iš plento, kalnų ir gravel padangų.',
  'search_page.schema_title.parts.cockpit': 'Dviračių vairai ir valdymo komponentai | Gearro',
  'search_page.schema_description.parts.cockpit':
    'Individualizuokite savo dviračio valdymą su aukštos kokybės vairo ir valdymo komponentais Gearro.',
  'search_page.schema_title.parts.seat': 'Dviračių sėdynės ir balneliai | Gearro',
  'search_page.schema_description.parts.seat':
    'Patirkite komfortą su mūsų dviračių sėdynių ir balnelių asortimentu. Raskite tinkamiausią variantą ilgoms kelionėms Gearro.',
  'verify_email_page.title': 'Patvirtinkite savo el. paštą',
  'verify_email_page.verify_email_address': 'Patvirtinkite savo el. paštą',
  'verify_email_page.finish_account_setup':
    'Norėdami patvirtinti savo el. pašto adresą, spustelėkite mygtuką el. laiške, kurį išsiuntėme į {email}',
  'verify_email_page.loading_user_information': 'Kraunama vartotojo informacija',
  'verify_email_page.email_not_received': 'Negavote el. laiško?',
  'verify_email_page.resend_email': 'Siųsti dar kartą',
  'verify_email_page.verification_error': 'Įvyko klaida... Bandykite dar kartą vėliau.',
  'verify_phone_page.title': 'Patvirtinkite savo telefono numerį',
  'verify_phone_page.verify_phone': 'Patvirtinkite savo telefono numerį',
  'verify_phone_page.phone_number': 'Telefono numeris',
  'verify_phone_page.phone_number_placeholder': '+48',
  'verify_phone_page.phone_number_required': 'Įveskite telefono numerį',
  'verify_phone_page.phone_number_invalid': 'Neteisingas telefono numeris',
  'verify_phone_page.incorrect_number_format': 'Telefono numeris nėra teisingame formate. Ar įtraukėte šalies kodą?',
  'verify_phone_page.code': 'Kodas',
  'verify_phone_page.code_placeholder': '4 skaitmenų kodas iš SMS',
  'verify_phone_page.code_required': 'Įveskite kodą',
  'verify_phone_page.code_invalid': 'Neteisingas kodas',
  'verify_phone_page.phone_number_info': 'Įveskite savo telefono numerį. Tai padidins jūsų paskyros saugumą.',
  'verify_phone_page.code_info_line_1': 'Mes išsiuntėme SMS žinutę į {phone}.',
  'verify_phone_page.code_info_line_2': 'Įveskite 4 skaitmenų kodą žemiau.',
  'verify_phone_page.loading_user_information': 'Kraunama vartotojo informacija',
  'verify_phone_page.resend_code': 'Negavote? Siųsti kodą dar kartą',
  'verify_phone_page.generic_error': 'Įvyko klaida... Bandykite dar kartą vėliau.',
  'verify_phone_page.already_created_error': 'Telefono numeris jau patvirtintas',
  'PrivacyPolicyPage.schemaTitle': 'Privatumo politika | {siteTitle}',
  'PasswordResetPage.passwordChangedHeading': 'Slaptažodis pakeistas',
  'PasswordResetPage.passwordChangedHelpText': 'Dabar galite prisijungti naudodami naują slaptažodį.',
  'PasswordResetPage.loginButtonText': 'Prisijungti',
  'PasswordResetPage.mainHeading': 'Atstatyti slaptažodį',
  'PasswordResetPage.helpText': 'Įveskite naują slaptažodį žemiau.',
  'PasswordResetForm.passwordLabel': 'Naujas slaptažodis',
  'PasswordResetForm.passwordPlaceholder': 'Įveskite naują slaptažodį',
  'PasswordResetForm.passwordRequired': 'Prašome įvesti naują slaptažodį',
  'PasswordResetForm.passwordTooShort': 'Slaptažodis turi būti bent {minLength} simbolių',
  'PasswordResetForm.passwordTooLong': 'Slaptažodis turi būti daugiausia {maxLength} simbolių',
  'PasswordResetForm.submitButtonText': 'Atkurti slaptažodį',
  'NotFoundPage.heading': 'Puslapis nerastas',
  'NotFoundPage.description': 'Puslapis, kurio ieškote, neegzistuoja arba buvo perkeltas.',
  'accept_terms_of_service_page.title': 'Paslaugų teikimo sąlygos',
  'accept_terms_of_service_page.accept_terms_of_service': 'Priimti paslaugų teikimo sąlygas',
  'accept_terms_of_service_page.accept_button': 'Sutinku',
  'accept_terms_of_service_page.generic_error': 'Įvyko klaida... Bandykite dar kartą vėliau.',
  'wallet.verify-your-identity': 'Patvirtinkite savo tapatybę',
  'wallet.identity-description':
    'Tai padeda mums patvirtinti jūsų tapatybę ir užtikrina, kad jūs esate tikrai tas, kuo prisistatote. Tapatybės patvirtinimas yra vienas iš būdų, kaip mes užtikriname saugumą Gearro.',
  'wallet.add-an-id': 'Pridėti asmens dokumentą',
  'wallet.choose-an-id-type': 'Pasirink, kokio tipo dokumentą pridėti',
  'wallet.driving-license': 'Vairuotojo pažymėjimas',
  'wallet.passport': 'Pasas',
  'wallet.identity-card': 'Asmens tapatybės kortelė',
  'wallet.front-of-the-document': 'Dokumento priekis',
  'wallet.upload-document-front': 'Įkelkite dokumento priekį.',
  'wallet.passport-page': 'Paso puslapis',
  'wallet.passport-description':
    'Įkelkite visą puslapį su jūsų nuotrauka, įsitikinkite, kad dvi eilutės apačioje būtų gerai įskaitomos.',
  'wallet.upload-from-device': 'Įkelti iš įrenginio',
  'wallet.check-quality': 'Patikrinti kokybę',
  'wallet.check-information': 'Įsitikinkite, kad informacija aiškiai matoma, be išsiliejimų ar akinimo.',
  'wallet.next': 'Kitas',
  'wallet.retake': 'Pakartoti',
  'wallet.flip-the-document': 'Apversti dokumentą',
  'wallet.flip-to-backside': 'Apverskite dokumentą ir įkelkite nugarėlę.',
  'wallet.error': 'Klaida',
  'wallet.error-description': 'Įvyko klaida tikrinant jūsų tapatybę. Bandykite dar kartą vėliau.',
  'wallet.try-again': 'Bandyti dar kartą',
  'wallet.thank-you': 'Ačiū',
  'wallet.thank-you-for-id':
    'Ačiū, kad pateikėte savo dokumentą. Netrukus pranešime, jei mums reikės papildomos informacijos.',
  'wallet.resume': 'Tuo tarpu galite tęsti nuo ten, kur baigėte.',
  'wallet.done': 'Baigta',
  'wallet.menu-title': 'Piniginė',
  'wallet.identity-verification-in-progress': 'Tapatybės tikrinimas vyksta...',
  'wallet-identity-verifaction-failed':
    'Tapatybės tikrinimas nepavyko. Patikrinkite savo duomenis ir bandykite dar kartą.',
  'wallet.identity-verified': 'Jūsų tapatybė patvirtinta. Galite tęsti savo operacijas.',
  'wallet.available-balance': 'Turimas likutis',
  'wallet.withdraw': 'Išsiimti į banko sąskaitą',
  'wallet.pending': 'Laukiama',
  'wallet.no-pending-balances': 'Nėra laukiančių likučių',
  'wallet.country-of-bank': 'Banko šalis',
  'wallet.full-name-required': 'Būtinas pilnas vardas',
  'wallet.full-name': 'Pilnas vardas',
  'wallet.name-and-surname': 'Vardas ir pavardė',
  'wallet.account-number-invalid': 'Neteisingas banko sąskaitos numeris',
  'wallet.bank-account-number': 'Banko sąskaitos numeris',
  'wallet.continue': 'Tęsti',
  'wallet.identity-verification': 'Tapatybės tikrinimas',
  'business.title': 'Patvirtinkite savo verslą',
  'business.business-details': 'Verslo detalės',
  'business.company-location': 'Įmonės vieta',
  'business.owner-information': 'Savininko informacija',
  'business.business-details.description': 'Prašome įvesti pagrindinę jūsų verslo identifikavimo informaciją.',
  'business.company-location.description': 'Prašome pateikti oficialų jūsų įmonės adresą.',
  'business.owner-information.description': 'Įveskite verslo savininko asmeninius duomenis.',
  'business.fields.business_name': 'Įmonės pavadinimas',
  'business.fields.business_name_placeholder': 'Įmonės pavadinimas',
  'business.fields.business_name_required': 'Būtinas įmonės pavadinimas',
  'business.fields.nip': 'NIP',
  'business.fields.nip_placeholder': 'NIP',
  'business.fields.nip_required': 'Būtinas NIP',
  'business.fields.region': 'Regionas',
  'business.fields.region_placeholder': 'Regionas',
  'business.fields.region_required': 'Būtinas regionas',
  'business.fields.company_address_line_1': 'Įmonės adreso eilutė 1',
  'business.fields.company_address_line_1_placeholder': 'Įmonės adreso eilutė 1',
  'business.fields.company_address_line_1_required': 'Būtinas įmonės adresas',
  'business.fields.company_address_line_2': 'Įmonės adreso eilutė 2',
  'business.fields.company_address_line_2_placeholder': 'Įmonės adreso eilutė 2',
  'business.fields.postal_code': 'Pašto kodas',
  'business.fields.postal_code_placeholder': 'Pašto kodas',
  'business.fields.postal_code_required': 'Būtinas pašto kodas',
  'business.fields.city': 'Miestas',
  'business.fields.city_placeholder': 'Miestas',
  'business.fields.city_required': 'Būtinas miestas',
  'business.fields.first_name': 'Vardas',
  'business.fields.first_name_placeholder': 'Vardas',
  'business.fields.first_name_required': 'Būtinas vardas',
  'business.fields.last_name': 'Pavardė',
  'business.fields.last_name_placeholder': 'Pavardė',
  'business.fields.last_name_required': 'Būtina pavardė',
  'business.fields.date_of_birth': 'Gimimo data',
  'business.fields.date_of_birth_placeholder': 'Gimimo data',
  'business.fields.date_of_birth_required': 'Būtina gimimo data',
  'listing_import_page.price_enter': 'Įveskite kainą',
  'listing_import_page.price_description':
    'Norėdami užbaigti savo skelbimą, nurodykite kainą, už kurią norite parduoti prekę.',
  'listing_import_page.link_to_listing': 'Nuoroda į jūsų skelbimą',
  'listing_import_page.link_to_listing_description':
    'Įveskite pilną savo skelbimo URL ir spustelėkite importuoti, kad įkeltumėte prekę į Gearro. Galėsite redaguoti skelbimą, kai tik jis bus sukurtas.',
  'listing_import_page.import_from_url': 'Importuoti iš URL',
  'listing_import_page.import_from_url_required': 'Prašome įvesti URL',
  'listing_import_page.confirm_import_listing':
    'Importuodami savo prekę, jūs patvirtinate, kad esate aukščiau pateiktos nuorodos savininkas ir suteikiate Gearro leidimą vykdyti importą.',
  'listing_import_page.continue': 'Tęsti',
  'listing_import_page.price': 'Kaina',
  'listing_import_page.listing_is_being_imported': 'Jūsų skelbimas yra importuojamas',
  'listing_import_page.see_my_ad': 'Peržiūrėti mano skelbimus',
  'listing_import_page.import_in_progress': 'Vyksta importavimas',
  'listing_import_page.import_in_progress_description': 'Patvirtinkite užsakymą, kad pradėtumėte siuntimo procesą',
  'listing_import_page.listing_verification': 'Skelbimo tikrinimas',
  'listing_import_page.listing_verification_description': 'Mūsų komandos skelbimo patikra gali užtrukti iki 48 valandų',
  'listing_import_page.listing_is_active': 'Skelbimas aktyvus',
  'listing_import_page.listing_is_active_description': 'Galite atlikti reikiamus pakeitimus',
  'listing_import_page.whats_next': 'Kas toliau?',
  'listing_type_select.badge_new': 'Naujas',
  'listing_type_select.import_from_link_title': 'Įkelti iš nuorodos',
  'listing_type_select.import_from_link_description': 'Turi skelbimą kitoje platformoje? Įklijuok nuorodą čia.',
  'listing_type_select.start_from_scratch_title': 'Įkelti pačiam',
  'listing_type_select.start_from_scratch_description': 'Suvesk prekės informaciją rankiniu būdu',
  'listing_type_select.time_content_import_from_link': '10 sekundžių',
  'listing_type_select.time_content_start_from_scratch': '1 minute',
  'listing_type_select.continue': 'Tęsti',
  'listing_type_select.how_would_you_like_to_post_your_listing': 'Kaip norėtum įkelti savo skelbimą?',
  'listing_type_select.imported_by_url': 'Importuota pagal URL',
  'listing_type_select.in_progress': 'Vykdoma',
  'listing_type_select.rejected': 'Atmesta',
  'listing_type_select.active': 'Aktyvus',
  'listing_type_select.simple_selling.title': 'Parduoti apsimoka',
  'listing_type_select.simple_selling.description': 'Parduok naujus ar naudotus dviračius, dalis ir žiemos įrangą',
  'listing_type_select.simple_selling.title_1': 'Patikima',
  'listing_type_select.simple_selling.description_1': 'Saugūs mokėjimai ir patikimas prekių pristatymas',
  'listing_type_select.simple_selling.title_2': 'Nemokama',
  'listing_type_select.simple_selling.description_2': 'Jokių paslėptų mokesčių',
  'listing_type_select.simple_selling.title_3': 'Matoma',
  'listing_type_select.simple_selling.description_3': 'Milijonai pirkėjų visoje Europoje',
  'listing_type_select.selling_steps.title': 'Parduoti paprasta',
  'listing_type_select.selling_steps.description': 'Lengvas, greitas ir sklandus būdas parduoti sporto įrangą.',
  'listing_type_select.selling_steps.title_1': 'Įkelk skelbimą',
  'listing_type_select.selling_steps.description_1':
    'Įkelkite savo įrangą rankiniu būdu arba importuokite per nuorodą.',
  'listing_type_select.selling_steps.title_2': 'Parduok visoje Europoje',
  'listing_type_select.selling_steps.description_2': 'Tavo skelbimą nemokamai matys milijonai pirkėjų',
  'listing_type_select.selling_steps.title_3': 'Gauk pinigus',
  'listing_type_select.selling_steps.description_3': 'Gauk 100 % grąžą – jokių paslėptų mokesčių.',
  'listing_type_select.easy_secure_way.title': 'Lengvas ir saugus būdas parduoti savo sportinę įrangą',
  'listing_type_select.easy_secure_way.description':
    'Mes tikime, kad naudotos sporto įrangos pirkimas ar pardavimas turėtų būti paprastas. Gearro platforma užtikrina saugius sandorius, patogų pristatymą ir tvaresnį būdą suteikti įrangai antrą gyvenimą.',
  'listing_type_select.keep_your_profit.title': 'Išsaugokite 100% savo pelno',
  'listing_type_select.keep_your_profit.description': 'Skelbimai ir pardavimai Gearro platformoje - nemokami.',
  'listing_type_select.keep_your_profit.button': 'Parduoti dabar',
  'faq.frequently_asked_questions': 'Dažniausiai užduodami klausimai',
  'faq.faq': 'DUK',
  'faq.account_and_general_questions': 'Gearro paskyra ir bendri klausimai',
  'faq.how_do_i_create_an_account_on_gearro': 'Kaip sukurti Gearro paskyrą?',
  'faq.how_do_i_reset_my_password': 'Kaip atkurti savo slaptažodį?',
  'faq.how_do_i_delete_my_gearro_account': 'Kaip ištrinti savo Gearro paskyrą?',
  'faq.is_it_safe_to_buy_and_sell_on_gearro': 'Ar saugu pirkti ir parduoti Gearro?',
  'faq.how_do_i_report_a_suspicious_listing_or_user': 'Kaip pranešti apie įtartiną skelbimą ar vartotoją?',
  'faq.how_can_i_contact_gearro_customer_support': 'Kaip susisiekti su Gearro klientų aptarnavimu?',
  'faq.selling_on_gearro': 'Pardavimas Gearro',
  'faq.selling_on_gearro.how_do_i_sell_an_item_on_gearro': 'Kaip parduoti prekę Gearro?',
  'faq.selling_on_gearro.is_it_free_to_list_an_item_on_gearro': 'Ar skelbimo paskelbimas Gearro yra nemokamas?',
  'faq.selling_on_gearro.how_can_i_delete_or_edit_my_listing': 'Kaip galiu ištrinti ar redaguoti savo skelbimą?',
  'faq.buying_on_gearro': 'Pirkimas Gearro',
  'faq.buying_on_gearro_how_do_i_buy_items_on_gearro': 'Kaip pirkti prekes Gearro?',
  'faq.buying_on_gearro_can_i_return_an_item_after_purchase': 'Ar galiu grąžinti prekę po pirkimo?',
  'faq.shipping': 'Siuntimas',
  'faq.shipping.how_does_dpd_shipping_work_on_gearro': 'Kaip veikia DPD siuntimas Gearro?',
  'faq.shipping.how_do_i_prepare_my_bike_for_shipping_with_dpd': 'Kaip paruošti dviratį siuntimui su DPD?',
  'faq.contact_us': 'Susisiekite su mumis',
  'faq.contact_us_description': 'Nedvejodami rašykite mums el. paštu {email}. Atsakysime per 24 valandas.',
  'packaging_instructions.schema_title': 'Pakuokite savo užsakymą | {siteTitle}',
  'packaging_instructions.page_title': 'Pakuojame jūsų prekę',
  'packaging_instructions.header_1': 'Pakuokite savo užsakymą',
  'packaging_instructions.header_2': 'Raskite dėžę',
  'packaging_instructions.header_3': 'Dviračio apsauga',
  'packaging_instructions.packing.title_1': 'Nuimkite pedalus',
  'packaging_instructions.packing.title_2': 'Nuimkite ratus',
  'packaging_instructions.packing.title_3': 'Nuimkite vairą',
  'packaging_instructions.packing.title_4': 'Nuimkite sėdynės stulpelį',
  'packaging_instructions.packing.title_5': 'Apsaugokite dviratį',
  'packaging_instructions.packing.title_6': 'Padarykite nuotraukas',
  'blog_page.schema_title': 'Naujausi straipsniai | {siteTitle}',
  'blog_page.page_title': 'Naujausi straipsniai',
  'blog_page.header': 'Naujausi straipsniai',
  'blog_page.not_found_schema_title': 'Straipsnis nerastas | {siteTitle}',
  'blog_page.not_found_title': 'Straipsnis nerastas',
  'blog_page.not_found_description': 'Straipsnis, kurio ieškote, neegzistuoja.',
  'faqs.title': 'DUK',
  'faqs.help_title': 'Reikia pagalbos?',
  'faqs.help_description': 'Mūsų palaikymo komanda jums padės bet kuriame pirkimo žingsnyje.',
  'faqs.help_contact': 'Susisiekite su mumis: ',
  'faqs.purchase_protection_title': 'Kas yra pirkimo apsauga?',
  'faqs.why_purchase_protection_title': 'Kodėl turėčiau naudoti Gearro pirkimo apsaugą?',
  'faqs.can_return_title': 'Ar galiu grąžinti užsakytą įrangą?',
  'faqs.why_pay_fee_title': 'Kodėl turiu mokėti mokestį?',
  'faqs.gear_shipped_title': 'Kaip siunčiama įranga?',
  'faqs.when_get_order_title': 'Kada gausiu savo užsakymą?',
  'faq.shipping.are_there_any_additional_fees_for_using_dpd_shipping_answer':
    'Papildomi mokesčiai už DPD siuntimą pardavėjams netaikomi. Pirkėjams siuntimo kaina rodoma atsiskaitymo metu.',
  'faqs.can_return_description':
    'Galite grąžinti prekę, jei ji žymiai skiriasi nuo skelbimo aprašymo arba pardavėjo pateiktos informacijos. Tačiau netinkamo dydžio ar jums nepatinkančios prekės nėra laikomos nemokamo grąžinimo priežastimi.',
  'faq.shipping.can_i_schedule_a_specific_pickup_time_for_shipping':
    'Ar galiu suplanuoti konkretų siuntos atsiėmimo laiką?',
  'faq.selling_on_gearro.is_it_free_to_list_an_item_on_gearro_answer':
    'Taip, galite nemokamai įkelti ir parduoti tiek prekių, kiek norite.',
  'faq.shipping.how_does_dpd_shipping_work_on_gearro_answer':
    'Pirkėjui įsigijus prekę, pardavėjas gauna iš anksto apmokėtą DPD siuntimo etiketę ir susitaria dėl siuntos atsiėmimo. Galite stebėti siuntos būseną per pateiktą sekimo informaciją.',
  'packaging_instructions.description_1':
    'Norėdami sukurti paskyrą, spustelėkite mygtuką „Registruotis“ viršutiniame meniu. Užsiregistruokite naudodami el. paštą arba prisijunkite per „Google“ paskyrą ir sekite nurodymus registracijos užbaigimui.',
  'faqs.when_get_order_description':
    'Pateikus pirkimo užklausą, pardavėjas turi 48 valandas jai patvirtinti. Mūsų logistikos partneris paprastai atsiima prekę iš pardavėjo per 4–6 darbo dienas. Tai leidžia pardavėjui tinkamai paruošti ir supakuoti prekę siuntimui.',
  'faq.how_do_i_delete_my_gearro_account_answer':
    'Jei svarstote galimybę ištrinti Gearro paskyrą, mes vertintume jūsų atsiliepimą. Norėdami ištrinti paskyrą pagal BDAR reikalavimus, rašykite mums el. paštu info@gearro.com.',
  'faq.how_do_i_reset_my_password_answer':
    'Jei pamiršote slaptažodį, prisijungimo metu pasirinkite „Pamiršote slaptažodį“. Jums bus atsiųsta el. laiškas su nuoroda slaptažodžio atkūrimui. Jei kyla problemų, susisiekite su mumis.',
  'faq.selling_on_gearro.how_can_i_delete_or_edit_my_listing_answer':
    'Norėdami ištrinti ar redaguoti skelbimą, prisijunkite prie savo paskyros, eikite į „Mano skelbimai“ ir pasirinkite norimą redagavimo arba ištrynimo parinktį.',
  'faq.buying_on_gearro_what_should_i_do_if_i_have_a_problem_with_a_transaction_answer':
    'Pirmiausia bandykite išspręsti problemą tiesiogiai su kita sandorio puse. Jei nepavyksta, susisiekite su Gearro klientų aptarnavimu ir pateikite sandorio informaciją.',
  'packaging_instructions.packing.description_1':
    'Jūsų dešinysis pedalas turi standartinę sriegtį (atsukamas prieš laikrodžio rodyklę), o kairysis pedalas turi atvirkštinę sriegtį (atsukamas pagal laikrodžio rodyklę).',
  'packaging_instructions.packing.description_5':
    'Burbulinė plėvelė: Apvyniokite rėmą ir nuimtas dalis burbuline plėvele, kad apsaugotumėte nuo įbrėžimų ir įlenkimų. Užtikrinkite, kad visos nuimtos dalys būtų saugiai supakuotos dėžėje.',
  'packaging_instructions.description_2':
    'Prieš pradėdami pakuoti, pasirūpinkite tinkamo dydžio siuntimo dėže. Dviračių parduotuvės gali būti puikus šaltinis, dažnai siūlančios dviračiams tinkamas dėžes nemokamai arba už minimalią kainą.',
  'faq.selling_on_gearro.how_do_i_sell_an_item_on_gearro_answer':
    'Parduoti lengva: sukurkite paskyrą, spustelėkite „Parduoti“, užpildykite prekės informaciją, įkelkite nuotraukas ir jūsų skelbimas bus aktyvus. Pirkėjai gali tiesiogiai su jumis susisiekti arba atlikti pirkimą.',
  'faq.how_do_i_create_an_account_on_gearro_answer':
    'Norėdami sukurti paskyrą, spustelėkite „Registruotis“ mygtuką viršutiniame meniu. Užsiregistruokite naudodami el. paštą arba prisijunkite per „Google“ paskyrą.',
  'faq.selling_on_gearro.how_can_i_increase_the_chances_of_selling_my_item_quickly_answer':
    'Pateikite išsamų ir sąžiningą aprašymą, pridėkite aukštos kokybės nuotraukas ir nustatykite konkurencingą kainą.',
  'faqs.why_pay_fee_description':
    'Mokestis padengia saugų mokėjimo apdorojimą, įvairių atsiskaitymo metodų prieinamumą ir patikimą sandorio valdymą.',
  'faq.how_do_i_report_a_suspicious_listing_or_user_answer':
    'Jei pastebėjote įtartiną skelbimą ar vartotoją, susisiekite su mūsų palaikymo komanda. Mes peržiūrėsime pranešimą ir imsimės atitinkamų veiksmų.',
  'packaging_instructions.packing.description_6':
    'Dokumentuokite savo siuntinio turinį bei vidinį ir išorinį pakavimą. Nuotraukos padės įrodyti tinkamą pakavimą, jei reikėtų pateikti pretenziją.',
  'faq.how_can_i_contact_gearro_customer_support_answer':
    'Susisiekite su mūsų klientų aptarnavimu siųsdami užklausą el. paštu info@gearro.com. Aprašykite savo situaciją, ir mūsų komanda greitai jums padės.',
  'packaging_instructions.description_3':
    'Nors bendrieji pakavimo principai gali būti naudingi, dviračio siuntimas reikalauja papildomo dėmesio. Laikykitės šių žingsnių, kad pakuotė būtų saugi ir efektyvi.',
  'packaging_instructions.packing.description_3':
    'Nuimkite vairą nuo vairo iškyšos. Palikite vairo iškyšą vietoje, kad išlaikytumėte suspaudimą vairo kolonėlėje.',
  'faq.buying_on_gearro_what_should_i_do_if_i_have_a_problem_with_a_transaction':
    'Jei iškilo problema dėl sandorio, pirmiausia pabandykite ją išspręsti tiesiogiai su pardavėju arba pirkėju. Jei nepavyksta, susisiekite su Gearro klientų aptarnavimo komanda ir pateikite sandorio informaciją.',
  'faq.selling_on_gearro.how_long_does_a_listing_stay_active_on_gearro_answer':
    'Skelbimai išlieka aktyvūs tol, kol prekė parduodama arba kol pats pardavėjas jį pašalina.',
  'faq.shipping.how_do_i_prepare_my_bike_for_shipping_with_dpd_answer':
    'Saugiai supakuokite dviratį į dviračio dėžę ar tinkamą pakuotę, nuimkite pedalus ir priekinį ratą, o transportavimo metu naudokite apsaugines medžiagas.',
  'faq.shipping.can_i_schedule_a_specific_pickup_time_for_shipping_answer':
    'Taip, įsigiję siuntimo etiketę, galite pasirinkti jums patogų laiką, kada DPD kurjeris atvyks paimti jūsų siuntos.',
  'faq.shipping.are_there_any_additional_fees_for_using_dpd_shipping':
    'Ar taikomi papildomi mokesčiai už DPD siuntimą?',
  'faqs.gear_shipped_description':
    'Prekės siunčiamos visiškai apdraustos per mūsų patikimus logistikos partnerius. Visą siuntimo laikotarpį jūsų prekė yra apsaugota nuo praradimo ar sugadinimo.',
  'faq.buying_on_gearro_can_i_return_an_item_after_purchase_answer':
    'Grąžinimai ir pinigų grąžinimai galimi per 48 valandas nuo pirkimo, jei pastebėjote problemą. Kiekvienas atvejis bus nagrinėjamas mūsų klientų aptarnavimo komandos.',
  'faqs.purchase_protection_description':
    'Gearro Pirkimo apsauga užtikrina saugų mokėjimą už naudotą įrangą. Jūsų pinigai laikomi saugiai tol, kol patvirtinate, kad gauta prekė atitinka aprašymą. Tai apsaugo jus nuo sukčiavimo.',
  'faq.buying_on_gearro_how_do_i_buy_items_on_gearro_answer':
    'Naršykite skelbimus, naudokite filtrus, peržiūrėkite detales ir pirkite spustelėdami „Pirkti dabar“. Galite susisiekti su pardavėju, jei turite klausimų.',
  'packaging_instructions.packing.description_4':
    'Nuimkite sėdynės stulpelį ir priveržkite sėdynės spaustuką. Tai padės užtikrinti, kad dviratis būtų kuo kompaktiškiau supakuotas ir apsaugotas transportavimo metu.',
  'faq.selling_on_gearro.how_can_i_increase_the_chances_of_selling_my_item_quickly':
    'Norėdami greičiau parduoti savo prekę, pateikite išsamų aprašymą, pridėkite aukštos kokybės nuotraukas ir nustatykite konkurencingą kainą.',
  'faq.is_it_safe_to_buy_and_sell_on_gearro_answer':
    'Taip, jei laikotės mūsų platformos rekomendacijų. Sandorius atlikite tik per Gearro, kad išvengtumėte sukčiavimo ir būtumėte apsaugoti Pirkėjo apsauga.',
  'faq.selling_on_gearro.how_long_does_a_listing_stay_active_on_gearro':
    'Kiek laiko skelbimas lieka aktyvus Gearro platformoje?',
  'faqs.why_purchase_protection_description':
    'Pirkdami naudotą įrangą internetu galite jausti riziką. Su Gearro Pirkimo apsauga jūsų mokėjimas išlieka saugus viso proceso metu, o jei prekė neatitinka aprašymo, turite teisę ją grąžinti.',
  'packaging_instructions.packing.description_2':
    'Nuimkite dviračio ratus. Jei dviratis turi diskinius stabdžius, į stabdžių suportus įdėkite stabdžių kaladėlių tarpiklį, kad stabdžių sistema liktų nepažeista.',
};
