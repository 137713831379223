import classNames from 'classnames';
import { bool, func, number, object, string } from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Box, Divider, Stack } from '@mui/material';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { Avatar, Logo, Menu, MenuContent, MenuItem, MenuLabel, NamedLink } from '../..';
import { intlShape } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';

import TopbarSearchForm from '../TopbarSearchForm/TopbarSearchForm';

import Spacer, { HORIZONTALL_AXIS, SMALL_SPACER } from '../../Spacer/Spacer';
import css from './TopbarDesktop.module.css';

import FavoriteIcon from '../../../assets/icons/hearts/outlined-24.png';
import MessageIcon from '../../../assets/icons/message.png';
import { LOCALES } from '../../../translations/locales';
import Dropdown from '../../Dropdown/Dropdown';
import { getDefaultCountry } from '../../../api/locale';

const categories = [
  // All bikes
  { type: 'bike', key: 'all_bikes', category: null },

  // Mountain bikes
  { type: 'bike', key: 'mountain', category: 'mountain' },

  // Road bikes
  { type: 'bike', key: 'road', category: 'road' },

  // Gravel bikes
  { type: 'bike', key: 'gravel', category: 'gravel' },

  // Enduro bikes (subcategory of mountain)
  { type: 'bike', key: 'enduro', category: 'mountain', subCategory: 'enduro' },

  // Bike parts
  { type: 'parts', key: 'parts', category: null },

  // Winter gear
  { type: 'winter', key: 'winter', category: null },
];

function TopbarDesktop(props) {
  const {
    className,
    appConfig,
    currentUser,
    rootClassName,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
    history,
    onLocaleChange,
    currentLocale,
  } = props;
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const classes = classNames(rootClassName || css.root, className);

  const search = (
    <TopbarSearchForm
      className={css.searchLink}
      inputRootClassName={css.topbarSearchWithLeftPadding}
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
      appConfig={appConfig}
    />
  );

  const ltOptions = [
    {
      value: LOCALES.LITHUANIAN,
      label: intl.formatMessage({ id: 'locales.lithuanian' }),
    },
    {
      value: LOCALES.ENGLISH,
      label: intl.formatMessage({ id: 'locales.english' }),
    },
  ];

  const plOptions = [
    {
      value: LOCALES.POLISH,
      label: intl.formatMessage({ id: 'locales.polish' }),
    },
    {
      value: LOCALES.ENGLISH,
      label: intl.formatMessage({ id: 'locales.english' }),
    },
  ];

  const allOptions = [
    {
      value: LOCALES.POLISH,
      label: intl.formatMessage({ id: 'locales.polish' }),
    },
    {
      value: LOCALES.ENGLISH,
      label: intl.formatMessage({ id: 'locales.english' }),
    },
    {
      value: LOCALES.LITHUANIAN,
      label: intl.formatMessage({ id: 'locales.lithuanian' }),
    },
  ]

  const options = process.env.REACT_APP_IS_SANDBOX === '1'
    ? allOptions
    : getDefaultCountry() === 'PL' ? plOptions : ltOptions;

  const notificationCountBadge =
    notificationCount > 0 ? (
      <div className={css.notificationDot}>
        <span>{notificationCount > 9 ? '9+' : notificationCount}</span>
      </div>
    ) : null;

  const inboxLink = authenticatedOnClientSide ? (
    <NamedLink
      className={css.inboxLink}
      name="InboxPage"
      params={{ lastLocation: history.location.pathname }}
      state={{ prevPath: history.location.pathname }}
    >
      <div className={css.inboxIconWrapper}>
        <img className={css.inboxIcon} src={MessageIcon} />
        {notificationCountBadge}
      </div>
    </NamedLink>
  ) : null;

  const favoriteListingsLink = authenticatedOnClientSide ? (
    <NamedLink
      name="FavoriteListingsPage"
      className={css.inboxLink}
      params={{ id: currentUser?.id?.uuid || 1 }}
      state={{ prevPath: history.location.pathname }}
    >
      <img className={css.inboxIcon} src={FavoriteIcon} />
    </NamedLink>
  ) : null;

  const profileMenu = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem className={css.menuLinkWrapper} key="ProfilePage">
          <NamedLink name="ProfilePage" params={{ id: currentUser?.id?.uuid || 1 }}>
            <span className={css.menuLinkText}>{intl.formatMessage({ id: 'top_bar.profile' })}</span>
          </NamedLink>
        </MenuItem>
        <MenuItem className={css.menuLinkWrapper} key="PurchasesPage">
          <NamedLink name="PurchasesPage">
            <span className={css.menuLinkText}>{intl.formatMessage({ id: 'top_bar.purchases' })}</span>
          </NamedLink>
        </MenuItem>
        <MenuItem className={css.menuLinkWrapper} key="SalesPage">
          <NamedLink name="SalesPage">
            <span className={css.menuLinkText}>{intl.formatMessage({ id: 'top_bar.sales' })}</span>
          </NamedLink>
        </MenuItem>
        <MenuItem className={css.menuLinkWrapper} key="WalletPage">
          <NamedLink name="WalletPage" params={{ id: currentUser?.id?.uuid || 1 }}>
            <span className={css.menuLinkText}>{intl.formatMessage({ id: 'wallet.menu-title' })}</span>
          </NamedLink>
        </MenuItem>
        <MenuItem className={css.menuLinkWrapper} key="FavoriteListingsPage">
          <NamedLink
            name="FavoriteListingsPage"
            params={{ id: currentUser?.id?.uuid || 1 }}
            state={{ prevPath: history.location.pathname }}
          >
            <span className={css.menuLinkText}>
              {intl.formatMessage({ id: 'top_bar.mobile_menu.favorite_listings' })}
            </span>
          </NamedLink>
        </MenuItem>
        <MenuItem className={css.menuLinkWrapper} key="AccountSettingsPage">
          <NamedLink name="AccountSettingsPage">
            <span className={css.menuLinkText}>{intl.formatMessage({ id: 'top_bar.account_settings_label' })}</span>
          </NamedLink>
        </MenuItem>
        {/* <MenuItem className={css.menuLinkWrapper} key="AboutPage">
          <NamedLink name="AboutPage">
            <span className={css.menuLinkText}>{intl.formatMessage({ id: 'top_bar.mobile_menu.about_page' })}</span>
          </NamedLink>
        </MenuItem> */}
        <MenuItem className={css.menuDividerWrapper} key="divider">
          <div className={css.divider} />
        </MenuItem>
        <MenuItem className={css.menuLinkWrapper} key="logout" onClick={onLogout}>
          <div className={css.logoutButton} onClick={onLogout}>
            <span className={css.menuLinkText}>{intl.formatMessage({ id: 'top_bar.log_out' })}</span>
          </div>
        </MenuItem>
        <MenuItem key="spacer">
          <Spacer size={8} />
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  const sellBikeLink = (
    <>
      <Spacer axis={HORIZONTALL_AXIS} size={16} />
      <NamedLink className={css.sellBikeLink} name="NewListingPage">
        {intl.formatMessage({ id: 'top_bar.sell' })}
      </NamedLink>
    </>
  );

  const faqLink = (
    <NamedLink name="FrequentlyAskedQuestions" className={css.faqLink}>
      {intl.formatMessage({ id: 'faq.faq' })}
    </NamedLink>
  );

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="LoginPage" className={css.loginLink}>
      {intl.formatMessage({ id: 'top_bar.log_in' })}
    </NamedLink>
  );

  // const categories = filters.find(filter => filter.id == 'category').config.options;

  return (
    <Stack justifyContent="center" alignItems="center" width="100%" className={css.shadow}>
      <nav className={classes}>
        <Stack
          className={css.firstNavRow}
          direction="row"
          width="100%"
          justifyContent="space-between"
          alignItems="center"
        >
          <NamedLink className={css.logoLink} name="LandingPage">
            <Logo
              format="desktop"
              className={css.logo}
              alt={intl.formatMessage({ id: 'top_bar.desktop.logo_label' })}
            />
          </NamedLink>
          {search}
          {/* {BuyBikeLink} */}
          {inboxLink}
          {favoriteListingsLink}
          <Spacer axis={HORIZONTALL_AXIS} size={SMALL_SPACER} />
          {profileMenu}
          <div className={css.loginSellButtonWrapper}>
            {loginLink}
            {sellBikeLink}
          </div>
          <Spacer axis={HORIZONTALL_AXIS} size={16} />
          {faqLink}
          <Spacer axis={HORIZONTALL_AXIS} size={16} />
          <Box mt={-1}>
            <Dropdown selectedOption={currentLocale} options={options} onOptionClick={onLocaleChange} />
          </Box>
        </Stack>
        <Divider sx={{ flexBasis: '100%', flexGrow: 1, minWidth: '100vw', position: 'absolute', left: 0, top: 72 }} />
        <Stack flexBasis="100%" flexGrow={1} direction="row" justifyContent="space-between" alignItems="center">
          {/* ALL CATEGORIES */}
          {/* <NamedLink name="SearchPage" state={{ prevPath: history.location.pathname }}>
            <Box
              componen="span"
              fontSize={16}
              fontWeight={400}
              color="text.primary"
              p={2}
              lineHeight="24px"
              sx={{
                fontWeight: 400,
                transition: 'all 0.2s ease-out',
                '&:hover': { color: 'primary.main' },
                whiteSpace: 'nowrap',
              }}
            >
              {intl.formatMessage({ id: `categories.none` })}
            </Box>
          </NamedLink> */}
          {/* OTHER CATEGORIES */}
          {categories.map(({ key, category, subCategory, type }) => (
            <NamedLink
              key={key}
              name="SearchPage"
              params={{ type, category, subCategory }}
              state={{ prevPath: history.location.pathname }}
            >
              <Box
                componen="span"
                fontSize={16}
                fontWeight={400}
                color="text.primary"
                p={2}
                lineHeight="24px"
                sx={{
                  fontWeight: 400,
                  transition: 'all 0.2s ease-out',
                  '&:hover': { color: 'primary.main' },
                  whiteSpace: 'nowrap',
                }}
              >
                {intl.formatMessage({ id: `top_bar.categories.${key}` })}
              </Box>
            </NamedLink>
          ))}
        </Stack>
      </nav>
    </Stack>
  );
}

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  appConfig: null,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  appConfig: object,
};

export default compose(withRouter)(TopbarDesktop);
