import { createContext, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLoadUser } from '../hooks/useLoadUser';
import { useLoadFxRate } from '../hooks/useLoadFxRate';
import { getDefaultCountry } from '../api/locale';

const ConversionContext = createContext({});

export const useConversionContext = () => {
  if (!ConversionContext) {
    throw new Error('useConversionContext must be used within a ConversionContextProvider');
  }
  return useContext(ConversionContext);
};

export const ConversionContextProvider = ({ children }) => {
  const isAuthenticated = useSelector(state => state.Auth.isAuthenticated);
  const loadedUser = useLoadUser(isAuthenticated);
  const loadedFxRate = useLoadFxRate(loadedUser?.countryCode || getDefaultCountry())

  return (
    <ConversionContext.Provider
      value={useMemo(() => ({ fxRate: loadedFxRate }), [loadedFxRate?.rate, loadedFxRate.loading])}
    >
      {children}
    </ConversionContext.Provider>
  );
};
